import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ServiceAccountsService from '../../../services/ServiceAccounts.service'

export const useDeleteServiceAcc = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { id: string }>({
    mutationKey: ['delete-service-acc'],
    mutationFn: (payload) => ServiceAccountsService.deleteServiceAcc(org!, payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['service-accounts'] })
    },
  })
}
