import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Input, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

const AddOrgPositionModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  loading: boolean
  onAdd?(name: string): void
}> = ({ open, setOpen, onAdd, loading }) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()
  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('OrgChart.addOrgPosition')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='pt-5 space-y-6'>
        <Input
          id='name'
          type='text'
          removePencil
          classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
          placeholder={t('Users.name') as string}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className='flex items-center justify-end gap-x-3'>
          <Button onClick={() => setOpen(false)} className='hover:bg-gray-100 active:text-blue-500'>
            {t('Groups.cancel')}
          </Button>
          <Button
            onClick={() => onAdd?.(name)}
            disabled={name === ''}
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            loading={loading}
          >
            {t('OrgChart.add')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default AddOrgPositionModal
