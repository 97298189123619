import { useQuery } from '@tanstack/react-query'
import OrganizationChartService from '../../services/OrganizationChart.service'
import { useParams } from 'react-router-dom'
import { OrgNodeResponse } from '../../models/OrganizationalNode'

// type OrgNodesOptions = {
//   page: number
//   size: number
// }

export const useOrgNodes = () => {
  const { org } = useParams()
  return useQuery<OrgNodeResponse>({
    queryKey: ['org-nodes', org],
    queryFn: () => OrganizationChartService.listAllOrgNodes(org!),
    enabled: !!org,
  })
}
