import React, { FC, Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { OrgNode } from '../../models/OrganizationalNode'
import { SpinnerIcon } from '@sistemiv/s-components'

const OrgPositionCombobox: FC<{
  options?: OrgNode[]
  selected?: OrgNode
  loading?: boolean
  disabled?: boolean
  onChange: (orgNode: OrgNode) => void
}> = ({ options, selected, onChange, loading, disabled }) => {
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  const filteredOptions =
    query === ''
      ? options
      : options?.filter((o) =>
          o.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  return (
    <Combobox onChange={onChange} disabled={disabled}>
      <div className='relative w-full'>
        <div className='relative rounded-md border border-gray-300 w-full h-full'>
          <div className={`flex items-center gap-x-3 w-full py-1.5 pr-9 pl-3 whitespace-nowrap overflow-hidden h-full`}>
            {selected ? (
              <p>{selected.name}</p>
            ) : (
              <p className='text-gray-400'>{t('ServiceAccounts.chooseOneOption')}</p>
            )}
          </div>
          {loading ? (
            <div className='absolute inset-y-0 flex items-center justify-center right-0 pr-2'>
              <SpinnerIcon className='w-4 h-4 text-sky-500' />
            </div>
          ) : (
            <Combobox.Button className='absolute inset-y-0 flex items-center justify-center right-0 pr-2'>
              <ChevronDownIcon className='w-5 h-5 text-gray-700' />
            </Combobox.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className='absolute mt-1 py-1 bg-white max-h-60 min-w-32 w-full overflow-auto z-10  rounded-md shadow-xl ring-1 ring-black ring-opacity-5'>
            <div className='w-full relative border-b border-gray-300'>
              <Combobox.Input
                displayValue={(value) => (value ? '' : '')}
                placeholder={t('ServiceAccounts.search')}
                onChange={(e) => setQuery(e.target.value)}
                className='relative pl-8 pr-3 bg-white border-none focus:border-none focus:ring-0 leading-5'
              />
              <span className='absolute flex items-center justify-center inset-y-0 left-0 pl-2'>
                <MagnifyingGlassIcon className='w-4 h-4 text-gray-400' />
              </span>
            </div>
            {filteredOptions?.map((o) => (
              <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                <div
                  className={`flex items-center gap-x-3 py-2 px-3 ${
                    selected?.id === o.id ? 'opacity-100' : 'opacity-70'
                  }`}
                >
                  <p>{o.name}</p>
                </div>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default OrgPositionCombobox
