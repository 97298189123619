import { useMutation, useQueryClient } from '@tanstack/react-query'
import GroupService from '../../../services/GroupService'
import { useParams } from 'react-router-dom'

type CreateGroupPayload = {
  organization: string
  icon: string
  name: string
}

export const useCreateGroup = () => {
  const { org } = useParams()
  const queryClient = useQueryClient()

  return useMutation<any, Error, CreateGroupPayload>({
    mutationKey: ['create-group'],
    mutationFn: ({ organization, icon, name }) => GroupService.create(organization, icon, name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups', org] })
    },
  })
}
