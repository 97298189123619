import { /*Base64SVG*/ Button, ImgWrapper, Input, SpinnerIcon, ThrashBinIcon } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { imageBase } from '../../services/http-common'
import { OrgNode } from '../../models/OrganizationalNode'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

export interface TabItem {
  id: string
  photo?: string
  icon?: string
  name: string
  objectId: string
}
const OrgMemberTab: FC<{
  items: TabItem[]
  linkName: string
  onDelete?(id: string): void
  onLinkClick?(id: string): void
  onSearch?(query: string): void
  fetching?: boolean
  selectedOrg: OrgNode | null
}> = ({ items, linkName, fetching, onDelete, onLinkClick, onSearch, selectedOrg }) => {
  const [pendingSearch, setPendingSearch] = useState('')
  const { org } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      onSearch?.(pendingSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingSearch, onSearch])
  return (
    <div className='relative'>
      <div className='w-1/4 pb-6'>
        {(items?.length || pendingSearch !== '') && (
          <Input
            id={'search'}
            type={'search'}
            classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100'
            placeholder={t('Groups.search') as string}
            value={pendingSearch}
            onChange={(e) => setPendingSearch(e.target.value)}
          />
        )}
      </div>
      {fetching && (
        <div className='w-1/3 flex items-center justify-center absolute'>
          <SpinnerIcon className='text-sky-500 w-8 h-8' />
        </div>
      )}
      {!items?.length ? (
        <p className=''>{t('OrgChart.noUsersHere')}</p>
      ) : (
        items?.map((item) => (
          <div
            className={`group grid grid-cols-[2fr_1fr] gap-x-20 py-1.5 pl-8 pr-4 hover:bg-blue-50 w-1/3 ${
              fetching ? 'opacity-50' : 'opacity-100'
            }`}
            key={item.id}
          >
            <div className='flex items-center gap-x-3'>
              <ImgWrapper
                src={`${imageBase}/${item.objectId}/76`}
                className='w-6 h-6 rounded-full'
                onErrorComponent={
                  <p className='text-white bg-[#7b87ae] font-semibold rounded-full p-1.5 flex items-center justify-center w-8 h-8'>
                    {item.name.charAt(0).toUpperCase()}
                  </p>
                }
              />
              <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{item.name}</span>
            </div>
            <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
              {selectedOrg?.name && selectedOrg?.name.toLowerCase() != org && (
                <Button className='!p-0' onClick={() => onDelete?.(item.id)}>
                  <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                </Button>
              )}
              <Button onClick={() => onLinkClick?.(item.objectId)} className='!p-0 text-blue-500'>
                {linkName}
              </Button>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default OrgMemberTab
