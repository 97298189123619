import { useMutation } from '@tanstack/react-query'
import UserService from '../../../services/UserService'
import { InviteUserRequest } from '../../../models/User'

type InviteUserPayload = {
  organization: string
  body: InviteUserRequest
}

export const useInviteUser = () => {
  // const queryClient = useQueryClient()

  return useMutation<any, Error, InviteUserPayload>({
    mutationKey: ['invite-user'],
    mutationFn: ({ organization, body }) => UserService.inviteUser(organization, body),
    // onSuccess: (_, { organization }) =>
    //   queryClient.invalidateQueries({ queryKey: ['profile-applications', organization] }),
  })
}
