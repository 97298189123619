import React, { FC, useEffect, useMemo, useState } from 'react'
import SettingsHeader from './SettingsHeader'
import { TabPanel, Tabs } from '@sistemiv/s-components'
import GeneralTab from './general-section/GeneralTab'
import PermissionsTab from './PermissionsTab'
import { useTranslation } from 'react-i18next'

type Tab = {
  key: 'general' | 'permissions'
  value: string
}
const SettingsPage: FC = () => {
  const { t } = useTranslation()
  const tabs: Tab[] = useMemo(
    () => [
      { key: 'general', value: t('Tabs.general') },
      { key: 'permissions', value: t('Tabs.permissions') },
    ],
    [t],
  )
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])

  useEffect(() => {
    setActiveTab((prev) => tabs.find((tab) => tab.key === prev.key)!)
  }, [t, tabs])

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <SettingsHeader />
      <div className=' p-8 grow overflow-auto'>
        <Tabs
          active={activeTab.value}
          tabs={tabs.map((tab) => tab.value)}
          onClick={(string) => {
            setActiveTab(tabs.find((tab) => tab.value === string)!)
          }}
          childrenClassName='!pt-0'
        >
          <TabPanel tab={tabs[0].value} active={activeTab.value}>
            <GeneralTab />
          </TabPanel>
          <TabPanel tab={tabs[1].value} active={activeTab.value}>
            <PermissionsTab />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default SettingsPage
