import { EditInput } from '@sistemiv/s-components'
import React, { FC } from 'react'
import {} from '@headlessui/react'
import { useUpdateOrganization } from '../../repositories/organizations/mutations/organization-patch.mutation'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

export type StatusAction = {
  id: string
  value: string
  onClick?: () => void
}

type CompanyAboutProps = {
  organizationName: string
  organizationCode: string
  legalCompanyName: string
  statusActions?: StatusAction[]
}

const CompanyAbout: FC<CompanyAboutProps> = ({ organizationCode, organizationName, legalCompanyName }) => {
  const { org } = useParams()
  const { mutate: updateField } = useUpdateOrganization()
  const { t } = useTranslation()

  return (
    <div className='mx-8'>
      <p className='text-[color:var(--app-bar-heading,#262A38)] text-base not-italic font-medium leading-6 tracking-[-0.48px];'>
        {t('Profile.about')}
      </p>
      <div className='flex flex-col py-4 max-w-xs gap-y-4'>
        <div className='space-y-1'>
          <label className='text-gray-500'>{t('CompanyProfile.orgName')}</label>
          <EditInput
            value={organizationName}
            onSave={(v) => org && updateField({ organization: org, field: 'name', body: { name: v } })}
          />
        </div>
        <div className='space-y-1'>
          <label className='text-gray-500'>{t('CompanyProfile.legalCompanyName')}</label>
          <EditInput
            value={legalCompanyName}
            onSave={(v) => org && updateField({ organization: org, field: 'legal-name', body: { legalName: v } })}
          />
        </div>
        <div className='space-y-1 '>
          <label className='text-gray-500'>{t('CompanyProfile.orgCode')}</label>
          <div className='flex items-center mb-4'>
            <span className='mr-2'>{organizationCode}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyAbout
