import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ServiceAccountsService from '../../services/ServiceAccounts.service'

type ServiceAccountsOptions = {
  page: number
  size: number
  name?: string
  organizationalNodeId?: string[]
}

export const useServiceAccounts = ({ page, size, name, organizationalNodeId }: ServiceAccountsOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['service-accounts', org, { page, size, name, organizationalNodeId }],
    queryFn: () => ServiceAccountsService.listServiceAccounts(org!, page, size, name, organizationalNodeId),
    placeholderData: keepPreviousData,
    enabled: !!org,
  })
}
