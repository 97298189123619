import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const OrgChartsHeader: FC<{
  onAddClick?(): void
  onExportClick?(): void
}> = ({ onAddClick }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-none items-center gap-x-16 border-b border-gray-300 px-8 py-3'>
      <p className='text-2xl'>{t('orgChart')}</p>
      <div className='flex items-center gap-x-4'>
        <Button
          onClick={onAddClick}
          className='text-blue-500 border border-blue-500 hover:bg-slate-50 active:!bg-slate-100 flex items-center gap-x-2'
        >
          <PlusIcon className='w-4 h-4 flex-none' />
          <>{t('OrgChart.orgPosition')}</>
        </Button>
        {/* <Button
          onClick={onExportClick}
          className='text-blue-500 border border-blue-500 hover:bg-slate-50 active:!bg-slate-100'
        >
          Export
        </Button> */}
      </div>
    </div>
  )
}

export default OrgChartsHeader
