import React, { FC, useCallback, useEffect, useState } from 'react'
import MembersHeader from './MembersHeader'
import { Button, DeleteModal, ImgWrapper, Input, ThrashBinIcon } from '@sistemiv/s-components'
import { useNavigate, useParams } from 'react-router-dom'
import GroupService from '../../services/GroupService'
import { AddMembersRequest, GroupMembersResponse } from '../../models/Group'
import AddMemberModal from './AddMemberModal'
import { imageBase } from '../../services/http-common'
import { useRemoveGroupMember } from '../../repositories/groups/mutations/groups-node-remove-member'
import { useTranslation } from 'react-i18next'

const MembersPage: FC = () => {
  const { org, id } = useParams()
  const [search, setSearch] = useState('')
  const [pendingSearch, setPendingSearch] = useState('')
  const [members, setMembers] = useState<GroupMembersResponse | null>(null)
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false)
  const [removingMemberId, setRemovingMemberId] = useState('')
  const { mutate: removeMember, isPending } = useRemoveGroupMember()
  const { t } = useTranslation()

  const [addMembersOpen, setAddMembersOpen] = useState(false)
  const navigate = useNavigate()

  const fetchMembers = useCallback(() => {
    if (!org || !id) return
    GroupService.listMembers(org, id, 0, 20, search !== '' ? search : undefined).then((response) => {
      if (response.total > 20) {
        GroupService.listMembers(org, id, 0, response.total, search !== '' ? search : undefined).then(setMembers)
      } else {
        setMembers(response)
      }
    })
  }, [org, id, search])

  useEffect(() => {
    fetchMembers()
  }, [fetchMembers, removeUserModalOpen])

  const handleDelete = () => {
    if (!org || !id || removingMemberId === '') return
    removeMember(
      { organization: org, id, memberId: removingMemberId },
      {
        onSuccess: () => {
          setRemoveUserModalOpen(false)
        },
      },
    )
  }

  const handleAdd = (userIds: string[]) => {
    if (!org || !id) return
    const body: AddMembersRequest = {
      users: userIds,
    }
    GroupService.addMembers(org, id, body).then(() => {
      setAddMembersOpen(false)
      fetchMembers()
    })
  }

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      setSearch(pendingSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingSearch])
  console.log(members)
  return (
    <div>
      <MembersHeader onAdMembers={() => setAddMembersOpen(true)} />
      <div className='p-8'>
        {((members && members?.members.length > 0) || search !== '') && (
          <div className='w-1/4 pb-6'>
            <Input
              id={'search'}
              type={'search'}
              classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100'
              placeholder={t('Users.search') as string}
              value={pendingSearch}
              onChange={(e) => setPendingSearch(e.target.value)}
            />
          </div>
        )}

        {!members?.members.length ? (
          <p className='text-[#5C6375]'>{t('Users.noMembers')}</p>
        ) : (
          members?.members?.map((member) => (
            <div
              className='group grid grid-cols-[2fr_1fr] gap-x-20 py-1.5 pl-8 pr-4 hover:bg-blue-50 w-1/3'
              key={member.id}
            >
              <div className='flex items-center gap-x-3'>
                <ImgWrapper
                  src={`${imageBase}/${member.objectId}/76`}
                  className='w-6 h-6 rounded-full'
                  onErrorComponent={
                    <p className='text-white bg-[#7b87ae] font-semibold rounded-full p-1.5 flex items-center justify-center w-8 h-8'>
                      {member.name.charAt(0).toUpperCase()}
                    </p>
                  }
                />
                <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{member.name}</span>
              </div>
              <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
                <Button
                  className='!p-0'
                  disabled={isPending}
                  onClick={() => {
                    setRemoveUserModalOpen(true)
                    setRemovingMemberId(member.id)
                  }}
                >
                  <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                </Button>
                <Button className='!p-0 text-blue-500' onClick={() => navigate(`/${org}/profile/${member.objectId}`)}>
                  {t('Users.profile')}
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
      {addMembersOpen && <AddMemberModal open={addMembersOpen} setOpen={setAddMembersOpen} onAdd={handleAdd} />}
      {removeUserModalOpen && (
        <DeleteModal
          title={t('Users.removeUser')}
          description={t('Users.removeFromGroupMsg')}
          open={removeUserModalOpen}
          setOpen={setRemoveUserModalOpen}
          onDeleteConfirmed={handleDelete}
          actionLabel={t('Users.remove') as string}
        />
      )}
    </div>
  )
}

export default MembersPage
