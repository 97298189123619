import { CameraIcon } from '@heroicons/react/24/solid'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useCurrentOrganization } from '../../repositories/organizations/organization.respository'
import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { Button, ImgWrapper } from '@sistemiv/s-components'
import { useUpdateOrganizationPhoto } from '../../repositories/organizations/mutations/organization-update-photo.mutation'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'

type CompanyProfileHeadersProps = {
  showBanner: boolean
}

const CompanyProfileHeaders: FC<CompanyProfileHeadersProps> = ({ showBanner }) => {
  const { data, isFetching } = useCurrentOrganization()
  const photoInputRef = useRef<HTMLInputElement>(null)
  const [companyImageUrl, setCompanyImageUrl] = useState('')
  const { mutate: updatePhoto } = useUpdateOrganizationPhoto()
  const { t } = useTranslation()
  const handlePhotoUpdate = (e: any) => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null
    if (!file || !data) return
    updatePhoto({ organization: data?.code, image: file })
  }

  useEffect(
    () => setCompanyImageUrl(`${imageBase}/${data?.code}/180?p=${new Date().getMilliseconds()}`),
    [data, isFetching],
  )

  return (
    <div>
      <div className='flex-none px-8 py-6 bg-gradient-to-b from-[#E1E2EA] to-white'>
        {showBanner && (
          <div
            className='@apply  flex flex-row justify-between w-full min-h-[64px] px-[rem] py-[1] shadow-[4px_0px_0px_0px_#0690DD_inset] mx-0 my-6'
            style={{ background: 'var(--notification-info-fill, #C0E8FF)' }}
          >
            <div className='flex items-center gap-[18px] flex-[1_0_0] pl-4 pr-3 py-3 justify-between '>
              <p
                className='  text-[color:var(--notification-info,#0690DD)] text-sm not-italic font-normal leading-[22px] tracking-[-0.42px];
'
              >
                {t('CompanyProfile.orgWillRemainActive')}
              </p>
              <Button className=' border border-[color:var(--notification-info,#0690DD)] rounded-md border-solid bg-[color:var(--notification-info,#0690DD)] text-[#fff]'>
                {t('CompanyProfile.reactivate')}
              </Button>
            </div>
          </div>
        )}
        <div
          onClick={() => {
            if (photoInputRef.current) {
              photoInputRef.current.click()
            }
          }}
          className='group w-20 h-20 rounded-full border-2 border-sky-500 relative overflow-hidden flex items-center justify-center bg-[#7B87AE] text-white select-none cursor-pointer'
        >
          <ImgWrapper
            className='rounded-full w-20 h-20 bg-white border-2 border-sky-500'
            src={companyImageUrl}
            onErrorComponent={
              <p className='rounded-full w-20 h-20 border-2 border-sky-500 bg-[#7b87ae] text-white flex items-center justify-center font-bold text-6xl'>
                {data ? data.name.charAt(0) : <BuildingOfficeIcon className='w-full h-full text-white p-2' />}
              </p>
            }
          />

          <span className='absolute z-10 flex justify-center items-center bottom-0 bg-black bg-opacity-30 group-hover:bg-opacity-50 w-full py-0.5'>
            <CameraIcon className='w-4 h-4' />
          </span>
        </div>
        <input
          type='file'
          className='hidden'
          accept='image/png,image/jpg,image/jpeg'
          ref={photoInputRef}
          onChange={handlePhotoUpdate}
        />
      </div>
    </div>
  )
}

export default CompanyProfileHeaders
