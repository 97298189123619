import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ModalDialog, Button } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useEffect, useState } from 'react'
import MemberPillCombobox from '../members/MemberPillCombobox'
import { useOrgChartMembers } from '../../repositories/organization-nodes/organization-nodes-members.repository'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AddOrgMembersModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onAdd?(members: any[]): void
  adding?: boolean
}> = ({ open, adding, setOpen, onAdd }) => {
  const { id } = useParams()
  const { data: nonMembersPage, fetchNextPage, hasNextPage } = useOrgChartMembers({ id, nodeMembers: false })
  const [selectedMembers, setSelectedMembers] = useState<any[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, nonMembersPage])
  const options = nonMembersPage?.pages
    .flat(Infinity)
    .map((r) => r.users)
    .flat()
    .map((member) => ({ id: member.id, name: member.name, objectId: member.objectId }))
  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('Users.addMembers')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='pt-5 space-y-6'>
        <MemberPillCombobox options={options ?? []} value={selectedMembers} onChange={setSelectedMembers} />
        <div className='flex items-center justify-end gap-x-3'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
            {t('Users.cancel')}
          </Button>
          <Button
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            onClick={() => onAdd?.(selectedMembers)}
            disabled={selectedMembers.length === 0}
            loading={adding}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default AddOrgMembersModal
