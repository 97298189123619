import { AddMembersRequest, CreateGroup, GroupMembersResponse, GroupNonMembersResponse } from '../models/Group'
import http from './http-common'

class GroupService {
  async create(organization: string, icon: string, name: string) {
    const body: CreateGroup = {
      name,
      icon,
    }
    return (await http.post(`/${organization}/group`, body)).data
  }

  async update(organization: string, id: string, field: string, body: any) {
    return (await http.put(`/${organization}/group/${id}/${field}`, body)).data
  }

  async delete(organization: string, id: string) {
    return (await http.delete(`/${organization}/group/${id}`)).data
  }

  async listMembers(
    organization: string,
    id: string,
    page = 0,
    size = 20,
    search?: string,
  ): Promise<GroupMembersResponse> {
    const params = {
      page,
      size,
      search,
    }
    return (await http.get(`/${organization}/group/${id}/member`, { params })).data
  }

  async deleteMember(organization: string, id: string, userId: string) {
    return (await http.delete(`/${organization}/group/${id}/member/${userId}`)).data
  }

  async addMembers(organization: string, id: string, body: AddMembersRequest) {
    return (await http.post(`/${organization}/group/${id}/member`, body)).data
  }

  async listNonMembers(
    organization: string,
    id: string,
    page = 0,
    size = 20,
    search?: string,
  ): Promise<GroupNonMembersResponse> {
    const params = {
      page,
      size,
      search,
    }
    return (await http.get(`/${organization}/group/${id}/non-members`, { params })).data
  }
}

export default new GroupService()
