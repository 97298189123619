import { useQuery } from '@tanstack/react-query'
import ProfileService from '../../services/Profile.service'
import { Profile } from '../../models/User'

type ProfileType = {
  id?: string
}

export const useProfile = ({ id }: ProfileType) => {
  return useQuery<Profile>({
    queryKey: ['profile', id],
    queryFn: () => ProfileService.get(id!),
    enabled: !!id,
  })
}
