import React, { FC } from 'react'

export const DetailItem: FC<{ label: string; value: string | string[] }> = ({ label, value }) => {
  return (
    <div>
      <h1 className='text-[#8087A0] text-sm'>{label}</h1>
      {Array.isArray(value) ? (
        value.map((item, index) => (
          <p className='mt-2 text-[#26282F]' key={index}>
            {item}
          </p>
        ))
      ) : (
        <p className='mt-2 text-[#26282F]'>{value != 'PendingApproval' ? value : 'Pending Approval'}</p>
      )}
    </div>
  )
}

export default DetailItem
