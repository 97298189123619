import { ChangePhoneModal, EditInput, Select, Option, RemovePhoneModal, ContactDetails } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { useUpdateOrganization } from '../../repositories/organizations/mutations/organization-patch.mutation'
import { useParams } from 'react-router'
import { countries } from '../../data'
import { useTranslation } from 'react-i18next'

type CompanyDetailsProps = {
  phone: string
  address: string
  city: string
  postCode: string
  taxNumber: string
  country: string
}

const CompanyDetails: FC<CompanyDetailsProps> = ({ address, city, country, phone, taxNumber, postCode }) => {
  const countryNames = Object.values(countries)
    .map((country) => country.name)
    .filter((v) => v !== country)
  const { org } = useParams()
  const { mutate: updateField } = useUpdateOrganization()
  const [addPhoneNumberOpen, setAddPhoneNumberOpen] = useState(false)
  const [changePhoneNumberOpen, setChangePhoneNumberOpen] = useState(false)
  const [deletePhoneNumberOpen, setDeletePhoneNumberOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className='mx-8'>
      <p className='text-[color:var(--app-bar-heading,#262A38)] text-base not-italic font-medium leading-6 tracking-[-0.48px];'>
        {t('CompanyProfile.companyDetails')}
      </p>
      <ContactDetails
        phone={phone}
        address={address}
        city={city}
        postCode={postCode}
        timeZone={''}
        country={''}
        onAddPhoneNumber={() => setAddPhoneNumberOpen(true)}
        onChangePhoneNumber={() => setChangePhoneNumberOpen(true)}
        onRemovePhoneNumber={() => setDeletePhoneNumberOpen(true)}
        onEdit={(key, value) => {
          if (!org) return
          if (key === 'post-code') {
            updateField({ organization: org, field: key, body: { postCode: value } })
          } else {
            updateField({ organization: org, field: key, body: { [key]: value } })
          }
        }}
      />
      <div className='space-y-1 mb-3'>
        <label className='text-gray-500'>{t('CompanyProfile.country')}</label>
        <div className='flex items-center mb-4'>
          <Select
            value={country}
            onChange={(v) => org && updateField({ organization: org, field: 'country', body: { country: v } })}
            className='rounded border-gray-300 p-2'
          >
            {countryNames.map((countryName, index) => (
              <Option key={index} value={countryName}>
                {countryName}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className='space-y-1'>
        <label className='text-gray-500'>{t('CompanyProfile.companyNumber')}</label>
        <EditInput
          value={taxNumber}
          onSave={(v) => org && updateField({ organization: org, field: 'tax-number', body: { taxNumber: v } })}
        />
      </div>
      {addPhoneNumberOpen && (
        <ChangePhoneModal
          setOpen={setAddPhoneNumberOpen}
          open={addPhoneNumberOpen}
          phone={phone}
          onContinue={(newPhone) => {
            if (!org) return
            updateField(
              { organization: org, field: 'phone', body: { phone: newPhone } },
              { onSuccess: () => setAddPhoneNumberOpen(false) },
            )
          }}
        />
      )}
      {changePhoneNumberOpen && (
        <ChangePhoneModal
          setOpen={setChangePhoneNumberOpen}
          open={changePhoneNumberOpen}
          phone={phone}
          onContinue={(newPhone) => {
            if (!org) return
            updateField(
              { organization: org, field: 'phone', body: { phone: newPhone } },
              { onSuccess: () => setChangePhoneNumberOpen(false) },
            )
          }}
        />
      )}
      {deletePhoneNumberOpen && (
        <RemovePhoneModal
          open={deletePhoneNumberOpen}
          setOpen={setDeletePhoneNumberOpen}
          onRemoveConfirmed={() => {
            if (!org) return
            updateField(
              { organization: org, field: 'phone', body: { phone: '' } },
              { onSuccess: () => setDeletePhoneNumberOpen(false) },
            )
          }}
        />
      )}
    </div>
  )
}

export default CompanyDetails
