import { useMutation, useQueryClient } from '@tanstack/react-query'
import ServiceAccountsService from '../../../services/ServiceAccounts.service'

type UpdateServiceAccPayload = {
  organization: string
  id: string
  node: string
}

export const useUpdateServiceAccNode = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateServiceAccPayload>({
    mutationKey: ['service-acc-update-node'],
    mutationFn: (payload) => ServiceAccountsService.updateOrgPosition(payload.id, payload.organization, payload.node),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['service-accounts'] })
    },
  })
}
