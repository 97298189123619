import React, { FC } from 'react'
import OrganizationDetails from './organization-details/OrganizationDetails'
import { useProfile } from '../../../repositories/profile/profile.repository'
import { useParams } from 'react-router-dom'
import { useProfileApplications } from '../../../repositories/profile/profile-applications.repository'
import { useApplications } from '../../../context/ApplicationsContext'
import { useMsal } from '@azure/msal-react'
import DetailItem from './DetailItem'
import { useTranslation } from 'react-i18next'

const Details: FC = () => {
  const { org, id } = useParams()
  const { instance } = useMsal()
  const { data: profile } = useProfile({ id })
  const { data: applications } = useProfileApplications({ organization: org, id })
  const { profileApps } = useApplications()
  const { t } = useTranslation()

  function checkShouldEditUser() {
    //user can't edit himself
    if (instance.getActiveAccount()?.localAccountId === id) return false
    //admin can't edit or deactivate org admin
    const loggedInUserRole = profileApps.find((el) => el.id === 's-organization')?.roles?.[0]?.name
    const userProfileRole = applications?.applications?.find((el) => el.id === 's-organization')?.roles?.[0]?.name
    if (loggedInUserRole === 'admin' && userProfileRole === 'org-admin') {
      return false
    } else return true
  }

  return (
    <div className='grid grid-cols-3 w-5/6 gap-x-6 pl-6 pr-7 '>
      <div className='flex flex-col gap-y-6'>
        <h1 className='text-lg font-medium text-[#262A38]'>{t('Profile.about')}</h1>
        <DetailItem label={t('Profile.firstName')} value={profile?.data.firstName ?? ''} />
        <DetailItem label={t('Profile.lastName')} value={profile?.data.lastName ?? ''} />
        <DetailItem label={t('Profile.jobTitle')} value={profile?.data.jobTitle ?? ''} />
        <DetailItem label={t('Profile.company')} value={profile?.data.company ?? ''} />
        <DetailItem label={t('Profile.email')} value={profile?.data.email ?? ''} />
      </div>
      <div className='flex flex-col gap-y-6'>
        <h1 className='text-lg font-medium text-[#262A38]'>{t('Profile.contactDetails')}</h1>
        <DetailItem label={t('Profile.phoneNumber')} value={profile?.data.phone ?? ''} />
        <DetailItem label={t('Profile.address')} value={profile?.data.address ?? ''} />
        <DetailItem label={t('Profile.city')} value={profile?.data.city ?? ''} />
        <DetailItem label={t('Profile.postCode')} value={profile?.data.postalCode ?? ''} />
      </div>
      <OrganizationDetails
        apps={applications?.applications ?? []}
        status={applications?.status ?? ''}
        groups={applications?.groups ?? []}
        units={applications?.units ?? []}
        readonly={!checkShouldEditUser()}
      />
    </div>
  )
}

export default Details
