import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type AddOrgMembersPayload = {
  organization: string
  id: string
  users: any[]
}

export const useAddOrgMembers = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, AddOrgMembersPayload>({
    mutationKey: ['addOrgMembers'],
    mutationFn: ({ organization, id, users }) => OrganizationChartService.addMembers(organization, id, { users }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization-nodes-members'] })
    },
  })
}
