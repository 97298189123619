import React, { FC } from 'react'
import { useParams } from 'react-router'
import UsersPage from '../users/UsersPage'
import GroupsPage from '../groups/GroupsPage'
import MembersPage from '../members/MembersPage'
import OrgChartsPage from '../org-charts/OrgChartsPage'
import OrgMembersPage from '../org-charts/OrgMembersPage'
import CompanyProfilePage from '../company-profile/CompanyProfilePage'
import ProfilePage from '../profile-page/ProfilePage'
import SettingsPage from '../settings/SettingsPage'
import ServiceAccountsPage from '../service-accounts/ServiceAccountsPage'

const DashboardSwitch: FC = () => {
  const { nav, id } = useParams()
  switch (nav) {
    case 'users':
      return <UsersPage />
    case 'groups':
      return id ? <MembersPage /> : <GroupsPage />
    case 'organizational-chart':
      return id ? <OrgMembersPage /> : <OrgChartsPage />
    case 'company-profile':
      return <CompanyProfilePage />
    case 'service-accounts':
      return <ServiceAccountsPage />
    case 'profile':
      return <ProfilePage />
    case 'settings':
      return <SettingsPage />
    default:
      return <UsersPage />
  }
}

export default DashboardSwitch
