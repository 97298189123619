import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import PrivateRoute from './PrivateRoute'
import Logout from '../pages/Logout'
import NotAuthorized from '../pages/NotAuthorized'

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path='/:org'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path='/:org/:nav'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path='/:org/:nav/:id'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path='/logout' element={<Logout />} />
        <Route path='/not-authorized' element={<NotAuthorized />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
