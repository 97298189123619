import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import PermissionsSettingsService from '../../services/PermissionsSettings.service'

type PermissionsOptions = {
  applicationId?: string
}

export const usePermissions = ({ applicationId }: PermissionsOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['permissions', org],
    queryFn: () => PermissionsSettingsService.list(org, applicationId),
    enabled: !!applicationId,
  })
}
