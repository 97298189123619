import React, { FC, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import { useOrganizations } from '../context/OrganizationsContext'
import ApplicationsContextProvider from '../context/ApplicationsContext'
import ErrorHandlingInterceptor from '../components/error-handling/ErrorHandlingInterceptor'

const PrivateRoute: FC<{ children?: any }> = ({ children }) => {
  const { org } = useParams()
  const navigate = useNavigate()
  const { organizations } = useOrganizations()
  useEffect(() => {
    if (org || organizations.length === 0) return
    navigate(`/${organizations.at(0)?.organizationCode}`)
  }, [organizations, org, navigate])
  const isAuthenticated = useIsAuthenticated()
  return isAuthenticated ? (
    <ErrorHandlingInterceptor>
      <ApplicationsContextProvider>{children}</ApplicationsContextProvider>
    </ErrorHandlingInterceptor>
  ) : (
    <Outlet />
  )
}

export default PrivateRoute
