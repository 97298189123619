import { useMutation } from '@tanstack/react-query'

import UserService from '../../../services/UserService'
import { useNavigate } from 'react-router'

type RemoveUserPayload = {
  organization: string
  id: string
}

export const useRemoveUser = () => {
  const navigate = useNavigate()

  return useMutation<any, Error, RemoveUserPayload>({
    mutationKey: ['removeUser'],
    mutationFn: ({ organization, id }) => UserService.removeUser(organization, id),
    onSuccess: (_, { organization }) => {
      navigate(`/${organization}/users`)
    },
  })
}
