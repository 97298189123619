import { useMutation, useQueryClient } from '@tanstack/react-query'
import GroupService from '../../../services/GroupService'
import { useParams } from 'react-router-dom'

type DeleteGroupPayload = {
  id: string
}

export const useDeleteGroup = () => {
  const { org } = useParams()
  const queryClient = useQueryClient()

  return useMutation<any, Error, DeleteGroupPayload>({
    mutationKey: ['delete-group'],
    mutationFn: ({ id }) => GroupService.delete(org!, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups', org] })
    },
  })
}
