import axios from 'axios'
import { loginRequest, msalInstance } from './AuthConfig'

export const getBaseUrl = (): string => {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'api-dev.sistemiv.com'
    case 'test':
      return 'api-test.sistemiv.com'
    case 'staging':
      return 'api-staging.sistemiv.com'
    default:
      return 'api.sistemiv.com'
  }
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

const stage = process.env.REACT_APP_STAGE
export const portalUrl = `https://portal${stage && stage !== 'prod' ? `-${stage}` : ''}.sistemiv.com`

export const imageBase = `https://${getBaseUrl()}/iam/api/profile/picture`

const axiosInstance = axios.create({
  baseURL: `https://${getBaseUrl()}/iam/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

let cachedAccessToken: string | null = null
let tokenExpiration: number | null = null

const authInterceptor = async (config: any) => {
  // const instance = msalInstance
  // const { accessToken } = await instance.acquireTokenSilent({
  //   ...loginRequest,
  //   account: instance.getActiveAccount() ?? undefined,
  // })
  // if (config.headers) {
  //   config.headers.Authorization = `Bearer ${accessToken}`
  // }
  // return config
  const instance = msalInstance
  const account = instance.getActiveAccount()

  if (!account) {
    // Nema aktivnog naloga, vraćamo originalni zahtev
    return config
  }

  const currentTime = Math.floor(Date.now() / 1000)

  if (cachedAccessToken && tokenExpiration && currentTime < tokenExpiration) {
    // Ako postoji keširani token i nije istekao, koristimo ga
    if (config.headers) {
      config.headers.Authorization = `Bearer ${cachedAccessToken}`
    }
    return config
  }

  try {
    const { accessToken, expiresOn } = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    })
    console.log('access token')
    console.log('expires: ', expiresOn)
    cachedAccessToken = accessToken
    tokenExpiration = expiresOn && Math.floor(expiresOn?.getTime() / 1000) // Token expiration in seconds

    if (config.headers) {
      config.headers.Authorization = `Bearer ${cachedAccessToken}`
    }
  } catch (error) {
    console.error('Error acquiring token silently', error)
  }

  return config
}

axiosInstance.interceptors.request.use(authInterceptor, (error) => Promise.reject(error))

export default axiosInstance
