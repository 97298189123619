import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { AccessCombobox, Button, GroupList, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useEffect } from 'react'
import { OrgPosition } from './OrgChartsTreeView'
import { useOrgChartMembers } from '../../repositories/organization-nodes/organization-nodes-members.repository'
import { useAddOrgMembers } from '../../repositories/organization-nodes/mutations/organization-nodes-add-members.mutation'
import { useNavigate, useParams } from 'react-router-dom'
import { useRemoveOrgMembers } from '../../repositories/organization-nodes/mutations/organization-node-remove-member'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'

const OrgMembersModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  orgPosition?: OrgPosition
}> = ({ open, setOpen, orgPosition }) => {
  const { org } = useParams()
  const {
    data: membersPages,
    hasNextPage: hasMembers,
    fetchNextPage: fetchMembers,
  } = useOrgChartMembers({ id: orgPosition?.id, nodeMembers: true })
  const {
    data: nonMembersPage,
    hasNextPage: hasNonMembers,
    fetchNextPage: fetchNonMembers,
  } = useOrgChartMembers({ id: orgPosition?.id, nodeMembers: false })
  const { mutate: addOrgMember } = useAddOrgMembers()
  const { mutate: removeOrgMember } = useRemoveOrgMembers()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleAddMember = (item: any) => {
    if (!org || !orgPosition) return
    addOrgMember({ organization: org, id: orgPosition?.id, users: [{ id: item.id, name: item.value }] })
  }

  const handleRemoveMember = (item: any) => {
    if (!org || !orgPosition) return
    removeOrgMember({ organization: org, id: orgPosition.id, memberId: item.id })
  }

  useEffect(() => {
    if (hasMembers) {
      fetchMembers()
    }
  }, [fetchMembers, hasMembers, membersPages])

  useEffect(() => {
    if (hasNonMembers) {
      fetchNonMembers()
    }
  }, [fetchNonMembers, hasNonMembers, nonMembersPage])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {`${t('Users.members')} ${orgPosition && t('Users.of') + orgPosition.name}`}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='space-y-6'>
        <div>
          <GroupList
            title={t('users')!}
            onRemove={handleRemoveMember}
            items={
              membersPages?.pages[0]?.users
                ? membersPages?.pages[0]?.users?.length > 3
                  ? membersPages?.pages[0].users.slice(0, 3).map((member) => ({
                      id: member.id ?? '',
                      type: 'circled_value',
                      value: member.name,
                      icon: `${imageBase}/${member.objectId}/76`,
                    }))
                  : membersPages?.pages[0].users.map((member) => ({
                      id: member.id ?? '',
                      type: 'circled_value',
                      value: member.name,
                      icon: `${imageBase}/${member.objectId}/76`,
                    }))
                : []
            }
          />
          {membersPages?.pages[0]?.users && membersPages?.pages[0]?.users?.length > 3 && (
            <Button
              onClick={() => navigate(`/${org}/organizational-chart/${orgPosition?.id}`)}
              className='!pl-0  text-blue-500'
            >
              {t('Users.seeAllMembers')}
            </Button>
          )}
          <AccessCombobox
            topMenu={nonMembersPage && nonMembersPage.pages ? nonMembersPage.pages[0].total > 4 : false}
            onChange={handleAddMember}
            options={
              nonMembersPage?.pages[0].users.map((member) => ({
                id: member.id ?? '',
                type: 'circled_value',
                value: member.name,
                icon: `${imageBase}/${member.objectId}/76`,
              })) ?? []
            }
            placeholder={t('Groups.addUsers') as string}
            inputPlaceholder={t('Groups.search') as string}
          />
        </div>
      </div>
    </ModalDialog>
  )
}

export default OrgMembersModal
