import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type RemoveOrgMembersPayload = {
  organization: string
  id: string
  memberId: string
}

export const useRemoveOrgMembers = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveOrgMembersPayload>({
    mutationKey: ['removeOrgMembers'],
    mutationFn: ({ organization, id, memberId }) => OrganizationChartService.removeMember(organization, id, memberId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization-nodes-members'] })
    },
  })
}
