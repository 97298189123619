import { OrgPosition } from '../components/org-charts/OrgChartsTreeView'
import { OrgMemberResponse } from '../models/OrganizationalNode'
import http from './http-common'
class OrganizationChartService {
  async tree(organization: string): Promise<OrgPosition[]> {
    return (await http.get(`/${organization}/organization-nodes/tree`)).data
  }

  // async list(organization: string, page: number, size: number): Promise<OrgNodeResponse> {
  //   return (
  //     await http.get(`/${organization}/organization-nodes`, {
  //       params: {
  //         page: page - 1,
  //         size,
  //       },
  //       paramsSerializer: { indexes: null },
  //     })
  //   ).data
  // }

  // Get organization nodes
  async listOrgNodesPaginate(organization: string, page: number, size: number, search?: string) {
    const params: { [key: string]: any } = {
      page: page,
      size: size,
    }
    if (search) params.search = search

    return (await http.get(`/${organization}/organization-nodes`, { params })).data
  }

  // Get organization nodes (all)
  async listAllOrgNodes(organization: string) {
    const orgNodes = await this.listOrgNodesPaginate(organization, 0, 1).then((res) => {
      return res
    })
    if (orgNodes.total <= 1) return orgNodes
    return this.listOrgNodesPaginate(organization, 0, orgNodes.total)
  }

  async add(organization: string, data: any) {
    return (await http.post(`/${organization}/organization-nodes`, data)).data
  }

  async remove(organization: string, nodeId: string) {
    return (await http.delete(`/${organization}/organization-nodes/${nodeId}`)).data
  }

  async update(organization: string, nodeId: string, body: { name: string }) {
    return (await http.put(`/${organization}/organization-nodes/${nodeId}`, body)).data
  }

  async updatePosition(organization: string, nodeId: string, body: { parentId: string | null; position: number }) {
    return (await http.post(`/${organization}/organization-nodes/${nodeId}/position`, body)).data
  }

  async fetchMembers(
    organization: string,
    id: string,
    page = 0,
    size = 20,
    search?: string,
    nodeMembers?: boolean,
  ): Promise<OrgMemberResponse> {
    return (
      await http.get(`/${organization}/organization-nodes/${id}/members`, {
        params: { page, size, search, nodeMembers },
      })
    ).data
  }

  async addMembers(organization: string, id: string, body: { users: any[] }) {
    return (await http.post(`/${organization}/organization-nodes/${id}/members`, body)).data
  }

  async removeMember(organization: string, id: string, memberId: string) {
    return (await http.delete(`/${organization}/organization-nodes/${id}/members/${memberId}`)).data
  }

  //custom fields

  async listCustomFields(organization: string) {
    return (await http.get(`/${organization}/organization-nodes/attributes`)).data
  }

  async addCustomField(organization: string, name: string) {
    return (await http.post(`/${organization}/organization-nodes/attributes`, { key: name })).data
  }

  async removeField(organization: string, id: string) {
    return (await http.delete(`/${organization}/organization-nodes/attributes/${id}`)).data
  }

  async updateField(organization: string, id: string, key: string) {
    return (await http.put(`/${organization}/organization-nodes/attributes/${id}`, { newKey: key })).data
  }

  async updateFieldValue(organization: string, nodeId: string, id: string, name: string) {
    return (await http.put(`/${organization}/organization-nodes/${nodeId}/attribute/${id}`, { value: name })).data
  }
}

export default new OrganizationChartService()
