import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { EditInput, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC } from 'react'
import { OrgPosition } from './OrgChartsTreeView'
import { useTranslation } from 'react-i18next'

const EditOrgPositionModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  orgPosition: OrgPosition
  onEdit?(name: string): void
}> = ({ open, setOpen, orgPosition, onEdit }) => {
  const { t } = useTranslation()
  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('OrgChart.editOrgPosition')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>

      <div className='pt-5 space-y-6'>
        <EditInput value={orgPosition.name} onSave={(value) => onEdit?.(value)} />
      </div>
    </ModalDialog>
  )
}

export default EditOrgPositionModal
