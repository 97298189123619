import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { Organization } from '../models/User'
import UserService from '../services/UserService'

interface OrganizationsContextType {
  organizations: Organization[]
  setOrganizations: any
}

const initialContext: OrganizationsContextType = {
  organizations: [],
  setOrganizations: () => undefined,
}

export const OrganizationsContext = createContext<OrganizationsContextType>(initialContext)

const OrganizationsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [organizations, setOrganizations] = useState<Organization[]>([])

  useEffect(() => {
    UserService?.listOrganizations().then((response) => setOrganizations(response.organizations))
  }, [])

  return (
    <OrganizationsContext.Provider value={{ organizations, setOrganizations }}>
      {children}
    </OrganizationsContext.Provider>
  )
}

export default OrganizationsContextProvider

export const useOrganizations = () => {
  return useContext(OrganizationsContext)
}
