import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { CustomField } from '../../models/OrganizationalNode'
import OrganizationChartService from '../../services/OrganizationChart.service'

export const useCustomFields = () => {
  const { org } = useParams()
  return useQuery<CustomField[]>({
    queryKey: ['custom-fields', org],
    queryFn: () => OrganizationChartService.listCustomFields(org!),
    enabled: !!org,
  })
}
