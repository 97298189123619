import { PlusIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { FC, useState } from 'react'
interface EmailChipsProps {
  value: string[]
  onChange?(value: string[]): void
  placeholder?: string
}
const EmailChips: FC<EmailChipsProps> = ({ value, onChange, placeholder }) => {
  const [email, setEmail] = useState('')
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handleKeyUpOrBlur = (e: any) => {
    if ((e.type === 'keyup' && [13, 32, 188].includes(e.keyCode)) || e.type === 'blur') {
      if (expression.test(email) && !value.includes(email)) {
        onChange?.([...value, email])
        setEmail('')
      }
    }
  }

  return (
    <div className='relative'>
      <div className='flex items-center gap-x-3 gap-y-2 flex-wrap border rounded-md border-gray-300 px-3 py-2 focus-within:border-sky-100 focus-within:ring-2 focus-within:ring-sky-100'>
        {value?.map((email) => (
          <div
            key={email}
            className='flex items-center gap-x-2 rounded-full py-1 px-2 bg-gray-200 border border-slate-300 text-slate-500 text-sm leading-4 max-w-full'
          >
            <div className='flex items-center gap-x-2 max-w-full'>
              <UserCircleIcon className='w-5 h-5' />
              <div className=' max-w-[350px] overflow-hidden whitespace-nowrap text-ellipsis'>{email}</div>
              <button className='flex items-center justify-center'>
                <XMarkIcon
                  className='w-4 h-4'
                  onClick={() => {
                    onChange && onChange(value.filter((v) => v !== email))
                  }}
                />
              </button>
            </div>
          </div>
        ))}
        <input
          disabled={value.length > 9}
          type='email'
          value={email}
          onKeyUp={handleKeyUpOrBlur}
          onBlur={handleKeyUpOrBlur}
          onChange={handleEmailChange}
          className={`w-full shrink relative border-none py-0 px-1 focus:border-none focus:ring-0
        ${value.length === 0 ? '!pl-5' : ''}`}
          placeholder={value.length === 0 ? placeholder : ''}
        />
        <span
          className={`absolute inset-y-0 flex items-center justify-center left-0 pl-2
                ${value.length === 0 ? 'flex' : 'hidden'}`}
        >
          <PlusIcon className='w-4 h-4 text-gray-500' />
        </span>
      </div>
    </div>
  )
}

export default EmailChips
