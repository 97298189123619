import {
  Button,
  ImgWrapper,
  SideMenuParentArrowIcon,
  SortableTree,
  SortableTreeItem,
  ThrashBinIcon,
} from '@sistemiv/s-components'
import { TreeItem } from '@sistemiv/s-components/dist/esm/types/types'
import { setProperty } from '@sistemiv/s-components/dist/esm/treeUtils'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FolderIcon, PlusIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import DndButtonIcon from '../../assets/DndButtonIcon'
import { useReorderOrgPosition } from '../../repositories/organization-nodes/mutations/organization-nodes-reorder.mutation'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'

export interface CustomAttribute {
  id: string
  key: string
  value: string | null
}
export interface OrgPosition {
  id: string
  name: string
  position: number
  children: OrgPosition[]
  attributes: CustomAttribute[]
}

const find = (array: any, id: string): any | undefined => {
  let result = undefined
  array.some((o) => (result = o.id === id ? o : find(o.children || [], id)))
  return result
}

// const find = (array, id) => {
//   let result = undefined
//   array.some((o) => (result = o.id === id ? o : find(o.children || [], id)))
//   return result
// }

const OrgChartsTreeView: FC<{
  orgPositions: OrgPosition[]
  rootNode?: OrgPosition
  onAddClicked?(position: OrgPosition | null): void
  onEditClicked?(position: OrgPosition): void
  onDeleteClicked?(position: OrgPosition): void
  onMembersClicked?(position: OrgPosition): void
  onDetailsClicked?(position: OrgPosition): void
  selected?: OrgPosition
}> = ({
  orgPositions,
  rootNode,
  onAddClicked,
  onEditClicked,
  onDeleteClicked,
  onMembersClicked,
  onDetailsClicked,
  selected,
}) => {
  const { org } = useParams()
  const navigate = useNavigate()
  // const mapToTreeItem = (array: any[]): TreeItem[] => {
  //   return array.reduce<TreeItem[]>((acc, item) => {
  //     return [...acc, { id: item.id, collapsed: false, children: mapToTreeItem(item.children), leaf: true }]
  //   }, [])
  // }
  // console.log(orgPositions)
  const mapToTreeItem = (array) => {
    return array
      .sort((a, b) => a.position - b.position)
      .reduce((acc, item) => {
        return [
          ...acc,
          {
            id: item.id,
            collapsed: false,
            children: mapToTreeItem(item.children),
            leaf: item.children.length === 0,
          },
        ]
      }, [])
  }
  const [treeItems, setTreeItems] = useState<TreeItem[]>([])
  const [expandedOrg, setExpandedOrg] = useState(true)
  const { mutate: reorderOrgPosition } = useReorderOrgPosition()
  const { t } = useTranslation()

  // useEffect(() => {
  //   setTreeItems(
  //     mapToTreeItem(
  //       orgPositions.at(0)?.children.sort((a, b) => {
  //         if ('children' in a) {
  //           a.children.sort((ac, bc) => ac.position - bc.position)
  //         }

  //         return a.position - b.position
  //       }) ?? [],
  //     ),
  //   )
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orgPositions])

  useEffect(() => {
    const sortedOrgPositions = orgPositions.map((item) => {
      if (item.children) {
        item.children.sort((a, b) => a.position - b.position)
        item.children = item.children.map((child) => {
          if (child.children) {
            child.children.sort((ac, bc) => ac.position - bc.position)
          }
          return child
        })
      }
      return item
    })

    setTreeItems(mapToTreeItem(sortedOrgPositions.at(0)?.children ?? []))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgPositions])

  const findParent = (array: any, id: string, currentParent = null) => {
    for (const item of array) {
      if (item.id === id) {
        return currentParent
      }
      if ('children' in item) {
        const parent = findParent(item.children, id, item)
        if (parent) {
          return parent
        }
      }
    }
  }

  // const findParent = (array, id, currentParent = null) => {
  //   for (const item of array) {
  //     if (item.id === id) {
  //       return currentParent
  //     }
  //     if ('children' in item) {
  //       const parent = findParent(item.children, id, item)
  //       if (parent) {
  //         return parent
  //       }
  //     }
  //   }
  //   return null
  // }

  // const handleReorder = (activeId, overId, parentId) => {
  //   if (!org) return

  //   const overProcess = find(orgPositions.at(0)?.children, overId)
  //   const activeProcess = find(orgPositions.at(0)?.children, activeId)
  //   const activeParent = findParent(orgPositions.at(0)?.children, activeId)
  //   const overParent = findParent(orgPositions.at(0)?.children, overId)

  //   if (overProcess) {
  //     let newPosition = overProcess.position

  //     if (!overParent) {
  //       // Scenario where overId is in the root level
  //       if (parentId) {
  //         // Moving into a new parent
  //         if (overId === parentId) {
  //           newPosition = 0 // Move to the beginning of the parent
  //         } else {
  //           const parentProcess = find(orgPositions.at(0)?.children, parentId)
  //           newPosition = parentProcess.children.length // Move to the end of the parent
  //         }
  //       } else {
  //         // Moving to the root level
  //         if (activeParent) {
  //           if (activeParent.id === overProcess.id) {
  //             newPosition = overProcess.position
  //           } else {
  //             newPosition = overProcess.position + 1
  //           }
  //         }
  //       }
  //     } else {
  //       // Scenario where overId is not in the root level
  //       if (activeId === overId) {
  //         newPosition = activeParent.position + 1
  //       } else if (parentId) {
  //         const currPosition = activeParent ? activeParent.position : activeProcess.position
  //         if (currPosition < overParent.position) {
  //           newPosition = overProcess.position + 1
  //         }
  //       } else if (!parentId) {
  //         if (activeParent) {
  //           newPosition = overParent.position + 1
  //         } else {
  //           newPosition = overParent.position
  //         }
  //       }
  //     }

  //     // Debugging output to help identify where the problem might be
  //     console.log('Active ID:', activeId)
  //     console.log('Over ID:', overId)
  //     console.log('Parent ID:', parentId)
  //     console.log('New Position:', newPosition)
  //     console.log('Active Parent:', activeParent)
  //     console.log('Over Parent:', overParent)
  //   }
  // }
  const handleReorder = (activeId: string, overId: string, parentId: string | null) => {
    if (!org) return
    const overProcess = find(orgPositions.at(0)?.children, overId)
    const activeProcess = find(orgPositions.at(0)?.children, activeId)
    const activeParent = findParent(orgPositions.at(0)?.children, activeId)
    const overParent = findParent(orgPositions.at(0)?.children, overId)
    if (overProcess) {
      let newPosition = overProcess.position
      if (!overParent) {
        if (parentId) {
          if (overId === parentId) {
            newPosition = 0
          } else {
            const parentProcess = find(orgPositions.at(0)?.children, parentId)
            newPosition = parentProcess.children.length
          }
        } else {
          if (activeParent) {
            if (activeParent.id === overProcess.id) {
              newPosition = overProcess.position
            } else {
              newPosition = overProcess.position + 1
            }
          }
        }
      } else {
        if (activeId === overId) {
          newPosition = activeParent.position + 1
        } else if (parentId) {
          const currPosition = activeParent ? activeParent.position : activeProcess.position
          if (currPosition < overParent.position) {
            newPosition = overProcess.position + 1
          }
        } else if (!parentId) {
          if (activeParent) {
            newPosition = overParent.position + 1
          } else {
            newPosition = overParent.position
          }
        }
      }

      reorderOrgPosition({
        organization: org,
        nodeId: activeId,
        parentId: parentId ? parentId : rootNode?.id ?? null,
        position: newPosition,
      })
    }
  }

  return (
    <div className='w-3/5'>
      <div
        className={`group flex items-center hover:bg-sky-50 py-2 px-3 gap-x-3 ${
          selected?.id === rootNode?.id ? 'bg-sky-100 ' : ''
        }active:bg-sky-100`}
      >
        <div className='flex items-center gap-x-3'>
          <button className={'opacity-0 group-hover:opacity-100 flex-none'}>
            <DndButtonIcon className='w-4 h-4 fill-gray-400' />
          </button>
          <button onClick={() => setExpandedOrg((val) => !val)}>
            <SideMenuParentArrowIcon className={`w-3 h-3 fill-black ${!expandedOrg ? 'rotate-0' : 'rotate-90'}`} />
          </button>
        </div>
        <div className='grow'>
          <div className='grid grid-cols-2 gap-x-20'>
            <div className='flex items-center gap-x-3 '>
              <ImgWrapper
                src={`${imageBase}/${org}/76`}
                className='w-6 h-6 rounded-full'
                onErrorComponent={
                  <p className='text-white bg-[#7b87ae] font-semibold rounded-full p-1.5 flex items-center justify-center w-8 h-8'>
                    {rootNode?.name.charAt(0).toUpperCase()}
                  </p>
                }
              />
              <span className='whitespace-nowrap text-ellipsis overflow-hidden capitalize'>{rootNode?.name}</span>
            </div>
            <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
              <Button onClick={() => onAddClicked?.(orgPositions.at(0) ?? null)} className='!p-0'>
                <PlusIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
              </Button>
              <Button onClick={() => onMembersClicked?.(orgPositions.at(0) as OrgPosition)} className='!p-0'>
                <UserCircleIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
              </Button>
              <Button
                onClick={() => navigate(`/${org}/organizational-chart/${rootNode?.id}`)}
                className='!p-0 text-blue-500'
              >
                {t('Groups.seeMembers')}
              </Button>
              <Button onClick={() => onDetailsClicked?.(rootNode!)} className='!p-0 text-blue-500'>
                {t('OrgChart.details')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {expandedOrg && (
        <SortableTree
          items={treeItems}
          setItems={setTreeItems}
          maxDepth={100}
          allowLeafParent={true}
          onReorder={handleReorder}
          renderElement={(id, depth, treeChildren, collapsed, clone) => {
            const orgPosition = find(orgPositions, id)
            if (!orgPosition) return null
            const item = orgPosition as OrgPosition

            return (
              <SortableTreeItem
                id={id}
                depth={depth}
                key={id}
                childCount={treeChildren.length}
                collapsed={collapsed}
                clone={clone}
                selected={id === selected?.id}
                onCollapse={() => {
                  setTreeItems((items) =>
                    setProperty(items, id, 'collapsed', (value) => {
                      return !value
                    }),
                  )
                }}
                data-testid={`org-position-item-${id}`}
              >
                <div className='grid grid-cols-2 gap-x-20'>
                  <div className={`flex items-center gap-x-3`} style={{ paddingLeft: `${(depth + 1) * 35}px` }}>
                    {treeChildren.length && treeChildren.length > 0 ? (
                      <button
                        onClick={() =>
                          setTreeItems((items) =>
                            setProperty(items, id, 'collapsed', (value) => {
                              return !value
                            }),
                          )
                        }
                      >
                        <SideMenuParentArrowIcon
                          className={`w-3 h-3 fill-black ${collapsed ? 'rotate-0' : 'rotate-90'}`}
                        />
                      </button>
                    ) : null}
                    <FolderIcon className='text-slate-400 w-7 h-7' />
                    <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{item.name}</span>
                  </div>
                  <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
                    <Button
                      onClick={() => onAddClicked?.(item)}
                      className='!p-0'
                      data-testid={`add-org-position-button-${id}`}
                    >
                      <PlusIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
                    </Button>
                    <Button onClick={() => onEditClicked?.(item)} className='!p-0'>
                      <PencilIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                    </Button>
                    <Button onClick={() => onMembersClicked?.(item)} className='!p-0'>
                      <UserCircleIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
                    </Button>
                    <Button onClick={() => onDeleteClicked?.(item)} className='!p-0'>
                      <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                    </Button>
                    <Button
                      onClick={() => navigate(`/${org}/organizational-chart/${id}`)}
                      className='!p-0 text-blue-500'
                    >
                      {t('Groups.seeMembers')}
                    </Button>
                    <Button onClick={() => onDetailsClicked?.(item)} className='!p-0 text-blue-500'>
                      {t('OrgChart.details')}
                    </Button>
                  </div>
                </div>
              </SortableTreeItem>
            )
          }}
        />
      )}
    </div>
  )
}

export default OrgChartsTreeView
