import { useMutation } from '@tanstack/react-query'
import UserService from '../../../services/UserService'
import { ApproveUserRequest } from '../../../models/User'

type ApproveUserPayload = {
  organization: string
  id: string
  body: ApproveUserRequest
}

export const useApproveUser = () => {
  // const queryClient = useQueryClient()

  return useMutation<any, Error, ApproveUserPayload>({
    mutationKey: ['approve-user'],
    mutationFn: ({ organization, id, body }) => UserService.approveUser(organization, id, body),
    // onSuccess: (_, { organization }) =>
    //   queryClient.invalidateQueries({ queryKey: ['profile-applications', organization] }),
  })
}
