import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type UpdateFieldValuePayload = {
  organization: string
  nodeId: string
  id: string
  name: string
}

export const useUpdateFieldValue = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateFieldValuePayload>({
    mutationKey: ['updateFieldValue'],
    mutationFn: ({ organization, nodeId, id, name }) =>
      OrganizationChartService.updateFieldValue(organization, nodeId, id, name),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
