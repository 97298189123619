import { Combobox, Transition } from '@headlessui/react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { FC, Fragment, useState } from 'react'
import { Group } from '../../models/Group'
import { Base64SVG } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'

interface GroupPillComboboxProps {
  options: Group[]
  value: Group[]
  onChange?: (value: any) => void
  label?: string
}
const GroupPillCombobox: FC<GroupPillComboboxProps> = ({ value, onChange, options, label }) => {
  const [query, setQuery] = useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  const filteredOptions =
    query === ''
      ? options.filter((o) => !value.includes(o))
      : options
          .filter((o) => !value.includes(o))
          .filter((o) => o.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

  return (
    <Combobox
      ref={inputRef}
      onChange={(value) => {
        inputRef.current?.focus()

        onChange && onChange(value)
        setQuery('')
      }}
      value={value}
      multiple
    >
      <div className='relative'>
        <div className='relative w-full'>
          <div className='flex items-center gap-x-3 gap-y-2 flex-wrap border rounded-md border-gray-300 px-3 py-2 focus-within:border-sky-100 focus-within:ring-2 focus-within:ring-sky-100'>
            {value?.map((group) => (
              <div
                key={group.id}
                className='flex items-center gap-x-2 rounded-full py-1 px-2 bg-gray-200 text-slate-500 text-sm leading-4'
              >
                <div className='flex items-center gap-x-2'>
                  <div className='rounded-full bg-[#6275A5] p-1.5 aspect-square'>
                    <Base64SVG base64image={group.icon} className='[&>svg]:h-4 [&>svg]:w-4 [&>svg>*]:fill-white' />
                  </div>
                  {group.name}
                </div>
                <button className='flex items-center justify-center'>
                  <XMarkIcon
                    className='w-4 h-4'
                    onClick={() => {
                      onChange && onChange(value.filter((v) => v.id !== group.id))
                    }}
                  />
                </button>
              </div>
            ))}
            <Combobox.Input
              placeholder={value.length === 0 ? (label ? label : t('addUsers')) : ''}
              onChange={(e) => setQuery(e.target.value)}
              className={`w-auto shrink relative border-none py-0 px-1 focus:border-none focus:ring-0
                ${value.length === 0 ? '!pl-5' : ''}`}
            />
            <Combobox.Button
              className={`absolute inset-y-0 flex items-center justify-center left-0 pl-2
                ${value.length === 0 ? 'flex' : 'hidden'}`}
            >
              <PlusIcon className='w-4 h-4 text-gray-500' />
            </Combobox.Button>
          </div>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
          appear={true}
        >
          <Combobox.Options className='absolute overflow-auto mt-1 z-10 min-w-sm max-h-60 w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 bg-white'>
            {filteredOptions.length === 0 && query !== '' ? (
              <div className='py-2 px-3 cursor-default select-none text-gray-700'>{t('Groups.nothingFound')}</div>
            ) : (
              filteredOptions?.map((option) => (
                <Combobox.Option
                  key={option.id}
                  value={option}
                  className='py-2 px-3 cursor-pointer select-none hover:bg-slate-100'
                >
                  <div className='flex items-center gap-x-2'>
                    <div className='rounded-full bg-[#6275A5] p-1.5 aspect-square'>
                      <Base64SVG base64image={option.icon} className='[&>svg]:h-4 [&>svg]:w-4 [&>svg>*]:fill-white' />
                    </div>
                    {option.name}
                  </div>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default GroupPillCombobox
