import React, { FC, useEffect, useState } from 'react'
import AppRouter from './router/AppRouter'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import { AuthError, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'
import OrganizationsContextProvider from './context/OrganizationsContext'
import toast, { Toaster } from 'react-hot-toast'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getCookie, portalUrl } from './services/http-common'
import { loginRequest, msalInstance } from './services/AuthConfig'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ErrorComponent: FC<{ error: AuthError | null }> = ({ error }) => {
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      // window.location.replace(`${portalUrl}?app=${window.location.href}`)
      msalInstance.acquireTokenRedirect(loginRequest)
    }
  }, [error])
  return null
}

const App: FC<{ pca: any }> = ({ pca }) => {
  const [first, setFirst] = useState(true)
  const handleError = (error: any): string => {
    let errorMessage = ''
    const response = error.response
    const data = response?.data
    const status = response?.status
    if (status === 404) {
      errorMessage = data?.message ?? 'Resource not found'
    } else if (status === 403) {
      errorMessage = 'Unauthorized request'
    } else {
      errorMessage = data?.errorMessage ?? 'An error occurred!'
    }
    return errorMessage
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
      mutations: {
        onError: (error) => {
          console.log(error)
          toast.error(handleError(error))
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.log(error)
        toast.error(handleError(error))
      },
    }),
  })

  useEffect(() => {
    msalInstance
      .ssoSilent(loginRequest)
      .then((response) => {
        console.log(response.account)
        msalInstance.setActiveAccount(response.account)
        setFirst(false)
      })
      .catch((err) => {
        if (err instanceof InteractionRequiredAuthError) {
          window.location.replace(`${portalUrl}/logout`)
        }
      })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (getCookie('refreshOrganization') === 'true') {
        document.cookie = `refreshOrganization=false; path=/; max-age=0; domain=.sistemiv.com`
        window.location.reload()
      }
    }, 1000)

    return () => clearInterval(interval)
  })

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Silent}
        authenticationRequest={{
          scopes: ['openid', 'fd1442d1-cbcc-4b73-a893-a61b8d3d4782'],
        }}
        errorComponent={ErrorComponent}
      >
        {!first && (
          <OrganizationsContextProvider>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
              <Toaster position='bottom-right' reverseOrder={false} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </OrganizationsContextProvider>
        )}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

export default App
