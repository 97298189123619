import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { FC, useState } from 'react'
interface DomainChipsProps {
  value: string[]
  onChange?(value: string[]): void
  readonly?: boolean
  placeholder?: string
}
const DomainChips: FC<DomainChipsProps> = ({ value, readonly, onChange, placeholder }) => {
  const [domain, setDomain] = useState('')
  const expression = /^[A-Z0-9.-]+\.[A-Z]{2,}$/i

  const handleDomainChange = (e: any) => {
    setDomain(e.target.value)
  }

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 13 && expression.test(domain)) {
      if (!value.includes(domain)) {
        onChange?.([...value, domain])
        setDomain('')
      }
    }
  }
  return (
    <div className='relative'>
      <div
        className={`flex items-center gap-x-3 gap-y-2 flex-wrap rounded-md px-3 py-2 border ${
          !readonly
            ? 'border-gray-300 focus-within:border-sky-100 focus-within:ring-2 focus-within:ring-sky-100'
            : 'border-transparent'
        }`}
      >
        {value?.map((domain) => (
          <div
            key={domain}
            className='flex items-center gap-x-2 rounded-full py-1 px-2 bg-gray-200 border border-slate-300 text-slate-500 text-sm leading-4'
          >
            <div className='flex items-center gap-x-2'>{domain}</div>
            <button className='flex items-center justify-center'>
              <XMarkIcon
                className='w-4 h-4'
                onClick={() => {
                  onChange && onChange(value.filter((v) => v !== domain))
                }}
              />
            </button>
          </div>
        ))}
        <input
          readOnly={readonly}
          disabled={value.length > 9}
          type='domain'
          value={domain}
          onKeyUp={handleKeyUp}
          onChange={handleDomainChange}
          className={`shrink relative border-none py-0 px-1 focus:!border-none focus:!ring-0 focus-visible:!ring-0 focus-visible:!border-none focus-visible:!outline-none`}
          placeholder={value.length === 0 ? placeholder : ''}
        />
      </div>
    </div>
  )
}

export default DomainChips
