import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type AddOrgPositionPayload = {
  organization: string
  parentId: string
  name: string
}

export const useAddOrgPosition = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, AddOrgPositionPayload>({
    mutationKey: ['addOrgPosition'],
    mutationFn: ({ organization, parentId, name }) => OrganizationChartService.add(organization, { name, parentId }),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
