import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, DeleteModal, PaginationController, SpinnerIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/24/solid'
import AddServiceAccountModal from './AddServiceAccountModal'
import { useAddServiceAcc } from '../../repositories/webhooks/mutation/add-service-acc.mutation'
import { useServiceAccounts } from '../../repositories/webhooks'
import Table from './ServiceAccountsTable'
import { useOrgNodes } from '../../repositories/organization-nodes/org-nodes.repository'
import { TableRow } from '../table/Table'
import { useDeleteServiceAcc } from '../../repositories/webhooks/mutation/delete-service-acc.mutation'
import EditServiceAccountModal from './EditServiceAccountModal'

const ServiceAccountsPage: FC = () => {
  const { org } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)
  const [addAccountModalOpen, setAddAccountModalOpen] = useState(false)
  const [nameSearch, setNameSearch] = useState('')
  const { t } = useTranslation()
  const [tableFilters, setTableFilters] = useState<{ [key: string]: any }>({
    orgPosition: [],
  })
  const {
    data: serviceAccounts,
    isLoading,
    isPending,
  } = useServiceAccounts({
    page: page,
    size: size,
    name: nameSearch,
    organizationalNodeId: tableFilters['orgPosition'].map((orgNode) => orgNode.id),
  })
  const { data: orgNodes, isLoading: loadNodes } = useOrgNodes()
  const { mutate: addServiceAcc, isPending: addingAcc } = useAddServiceAcc()
  const [editingAcc, setEditingAcc] = useState<any>(undefined)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { mutateAsync: deleteAccountMutation, isPending: removingAccount } = useDeleteServiceAcc()

  const newRows = serviceAccounts?.serviceAccounts?.map((acc) => {
    const retval: TableRow = {
      id: {
        type: 'plainText',
        value: acc?.id,
      },
      name: {
        type: 'plainText',
        value: acc?.name,
      },
      credentials: {
        type: 'plainText',
        value: acc?.credentialType,
      },
      credentialsValue: {
        type: 'secret_field',
        value: acc?.credentialId,
      },
      orgPosition: {
        type: 'plainText',
        value:
          orgNodes?.results &&
          orgNodes?.results?.length > 0 &&
          orgNodes?.results.find((node) => node.id === acc?.organizationNodeId)?.name,
      },
    }

    return retval
  })

  const headers = [
    {
      accessor: 'name',
      label: t('ServiceAccounts.name'),
      searchType: 'search',
    },
    {
      accessor: 'credentials',
      label: t('ServiceAccounts.credentials'),
      searchType: '',
    },
    {
      accessor: 'credentialsValue',
      label: t('ServiceAccounts.credentialsValue'),
      searchType: '',
    },
    {
      accessor: 'orgPosition',
      label: t('ServiceAccounts.orgPosition'),
      searchType: 'select',
      options: orgNodes?.results,
    },
    {
      accessor: 'actions',
      label: t('ServiceAccounts.actions'),
      searchType: '',
    },
  ]
  useEffect(() => {
    if (!serviceAccounts) return
    if (serviceAccounts?.total < page * size) {
      setPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAccounts, size, page])

  const onEditClick = (id: string) => {
    setEditingAcc(serviceAccounts?.serviceAccounts?.find((acc) => acc.id === id))
    setEditModalOpen(true)
  }

  const onDeleteClick = (id: string) => {
    setEditingAcc(serviceAccounts?.serviceAccounts?.find((acc) => acc.id === id))
    setDeleteModalOpen(true)
  }

  const handleCreateAccount = (name: string, nodeId: string) => {
    addServiceAcc(
      {
        body: { name: name, nodeId: nodeId },
      },
      {
        onSuccess: () => {
          setAddAccountModalOpen(false)
        },
      },
    )
  }

  const handleDeleteAcc = () => {
    if (!org || !editingAcc) return
    deleteAccountMutation(
      { id: editingAcc.id },
      {
        onSuccess: () => {
          setDeleteModalOpen(false)
          setEditingAcc(undefined)
        },
      },
    )
  }

  const handleEditCancel = () => {
    setEditModalOpen(false)
    setEditingAcc(undefined)
  }

  return (
    <div className='overflow-hidden flex flex-col h-full w-full'>
      <div className='flex flex-none items-center justify-between gap-x-16 border-b border-gray-300 px-8 py-3'>
        <div className='flex gap-x-16'>
          <p className='text-2xl'>{t('serviceAccounts')}</p>
          <div className='flex items-center gap-x-4'>
            <Button
              onClick={() => setAddAccountModalOpen(true)}
              className='text-blue-500 border border-blue-500 hover:bg-slate-50 active:!bg-slate-100 flex items-center gap-x-2'
            >
              <PlusIcon className='w-4 h-4 flex-none' />
              <>{t('ServiceAccounts.serviceAccount')}</>
            </Button>
          </div>
        </div>
        <PaginationController
          total={serviceAccounts?.total ?? 1}
          size={size}
          page={page + 1}
          onPageChange={(page) => setPage(page - 1)}
          onSizeChange={(size) => setSize(size)}
        />
      </div>
      <div className={`px-8 pt-3 py-1 grow overflow-hidden ${isLoading ? 'opacity-50' : 'opacity-100'}`}>
        <div className='w-full  relative py-2 bg-[#E1F4FF] mb-3 flex'>
          <div className='w-2 absolute left-0  top-0 h-full bg-[#38A0DB]'></div>
          <p className='text-[#38A0DB] px-4'> {t('ServiceAccounts.infoMsg')}</p>
        </div>
        <div className='w-full h-full overflow-auto relative'>
          {(isLoading || isPending || loadNodes) && (
            <div className='absolute first:w-full flex justify-center items-center pt-5'>
              <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
            </div>
          )}
          <Table
            headers={headers}
            rows={newRows}
            onEdit={onEditClick}
            onDelete={onDeleteClick}
            onNameSearch={setNameSearch}
            onHeaderFilter={(field, value) =>
              setTableFilters((old) => {
                return {
                  ...old,
                  [field]: value ?? undefined,
                }
              })
            }
            filterValues={tableFilters}
          />
        </div>
      </div>
      {addAccountModalOpen && (
        <AddServiceAccountModal
          open={addAccountModalOpen}
          setOpen={setAddAccountModalOpen}
          isAdding={addingAcc}
          onAdd={handleCreateAccount}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('ServiceAccounts.deleteServiceAcc')}
          description={t('ServiceAccounts.deleteServiceAccMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDeleteAcc}
          isLoading={removingAccount}
        />
      )}
      {editModalOpen && (
        <EditServiceAccountModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          onCancel={handleEditCancel}
          editingAcc={editingAcc}
        />
      )}
    </div>
  )
}

export default ServiceAccountsPage
