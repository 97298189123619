import React, { FC } from 'react'
import Header from './header/Header'
import ProfileImageWrapper from './ProfileImageWrapper'
import Details from './details/Details'

const ProfilePage: FC = () => {
  return (
    <div className='overflow-hidden flex flex-col h-full w-full'>
      <Header />
      <div className='overflow-y-auto flex-1'>
        <ProfileImageWrapper />
        <Details />
      </div>
    </div>
  )
}
export default ProfilePage
