import React, { FC, useEffect, useState } from 'react'
import { ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/cjs/types/types'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ComboboxPills } from '@sistemiv/s-components'
import { Select, Option, Button, Input } from '@sistemiv/s-components'
import UserService from '../../services/UserService'
import { useParams } from 'react-router-dom'
import GroupPillCombobox from '../groups/GroupPillCombobox'
import { ApproveModalResponse, ApproveUserRequest } from '../../models/User'
import { OrgNode } from '../../models/OrganizationalNode'
import { Group } from '../../models/Group'
import { Role } from '../../models/Roles'
import { useTranslation } from 'react-i18next'

type InviteUsersModalProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onApprove?: (value: any) => void
  isLoading: boolean
}

const ApproveUserModal: FC<InviteUsersModalProps> = ({ open, setOpen, onApprove, isLoading }) => {
  const { org } = useParams()
  const [groups, setGroups] = useState<Group[]>([])
  const [orgPositions, setOrgPositions] = useState<OrgNode[]>([])
  const [approveUserData, setApproveUserData] = useState<ApproveModalResponse | null>(null)
  const [selectedAppsRole, setSelectedAppRole] = useState<{ [key: string]: Role | undefined }>({})
  const { t } = useTranslation()

  useEffect(() => {
    if (!org) return
    UserService.fetchApproveUserModal(org).then(setApproveUserData)
  }, [org])

  useEffect(() => console.log(approveUserData), [approveUserData])

  const approveDisabled = () => {
    if (orgPositions.length < 1) return true
    return false
  }

  const handleApprove = () => {
    if (!onApprove) return
    const body: ApproveUserRequest = {
      groupIds: groups.map((g) => g.id),
      nodeId: orgPositions[0].id,
      roles: Object.keys(selectedAppsRole)?.map((key) => ({
        roleId: selectedAppsRole[key]!.id,
        applicationId: key,
      })),
    }
    onApprove(body)
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('Users.approveRequest')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='space-y-6 mt-5'>
        <GroupPillCombobox
          label={t('Groups.addToGroups') as string}
          value={groups}
          onChange={(value) => setGroups(value)}
          options={approveUserData?.groups ?? []}
        />
        <ComboboxPills
          label={t('Groups.addToOrgPosition') as string}
          value={orgPositions?.map((op) => op.name)}
          onChange={(value) => {
            if (value.length === 1) {
              setOrgPositions(value?.map((v) => approveUserData?.nodes.find((n) => n.name === v)))
            } else if (value.length === 0) {
              setOrgPositions([])
            }
          }}
          options={approveUserData?.nodes?.map((node) => node.name) ?? []}
        />

        {approveUserData?.applications?.map((application) => (
          <div key={application.id} className='grid grid-cols-2 items-center'>
            <Input
              id={application.id}
              type='checkbox'
              label={application.id}
              checked={application.id in selectedAppsRole}
              onChange={(e) => {
                if (e.target.checked && !(application.id in selectedAppsRole)) {
                  setSelectedAppRole((old) => ({ ...old, [application.id]: application.roles.at(0) }))
                } else if (!e.target.checked && application.id in selectedAppsRole) {
                  setSelectedAppRole((old) => {
                    const copy = { ...old }
                    delete copy[application.id]
                    return copy
                  })
                }
              }}
              mainClasses='capitalize'
            />
            {application.id in selectedAppsRole && (
              <Select
                className='capitalize'
                value={selectedAppsRole[application.id]?.code.replaceAll('-', ' ')}
                onChange={(value) => {
                  setSelectedAppRole((old) => ({
                    ...old,
                    [application.id]: application.roles.find((r) => r.id === value),
                  }))
                }}
              >
                {application.roles?.map((role) => (
                  <Option key={role.id} value={role.id}>
                    <p className='capitalize'>{role.code.replaceAll('-', ' ')}</p>
                  </Option>
                ))}
              </Select>
            )}
          </div>
        ))}
        <div className='flex justify-end mt-5'>
          <Button className='mr-3 hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
            {t('Groups.cancel')}
          </Button>
          <Button
            className='mr-3 bg-blue-500 disabled:bg-slate-300 text-white'
            onClick={handleApprove}
            disabled={approveDisabled()}
            loading={isLoading}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default ApproveUserModal
