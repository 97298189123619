import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import DataField from '../table-header/DataField'
import { Menu } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import TableHeaderCombobox from '../table-header/TableHeaderCombobox'
import TableHeaderSearchCobobox from '../table-header/TableHeaderSearchCombobox'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { Roles } from '../../models/Roles'
import RolesModal from '../roles-modal/RolesModal'
import { useMsal } from '@azure/msal-react'
import ApproveUserModal from '../approve-user-modal/ApproveUserModal'
import { ApproveUserRequest } from '../../models/User'
import { DeleteModal } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { useRemoveUser } from '../../repositories/users/mutations/remove-user.mutation'
import { useDeactivateUser } from '../../repositories/users/mutations/deactivate-user.mutation'
import { useApproveUser } from '../../repositories/users/mutations/approve-user.mutation'

export type TableHeaders = {
  accessor: string
  label: string
  searchType: string
  options?: any[]
  searchValue?: string
}
type TableAction = {
  name: string
  icon?: string
  onClick: (e: any) => void
}

export type TableField = {
  type:
    | 'name'
    | 'plainText'
    | 'coloredText'
    | 'linkText'
    | 'checkbox'
    | 'circle_label'
    | 'circled_value'
    | 'app_roles'
    | 'secret_field'
  value: any
  image?: string
  color?: string
  role?: string
  id?: string
  base64image?: string
  checked?: boolean
  readOnly?: boolean
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  onShow?: () => void
}

export type TableRow = {
  [key: string]: TableField
}

type TableProps = {
  headers: TableHeaders[]
  rows: TableRow[]
  onHeaderFilter?: (field: string, value: any) => void
  refreshTable?: () => void
  filterValues: { [key: string]: any }
}

const Table: FC<TableProps> = ({ headers, rows, onHeaderFilter, filterValues, refreshTable }) => {
  const { instance } = useMsal()
  const [tableData, setTableData] = useState<TableRow[]>(rows)
  const [appRoles, setAppRoles] = useState<{ [key: string]: Roles }>({})
  const [accessModalOpen, setAccessModalOpen] = useState(false)
  const [roleAppSelected, setRoleAppSelected] = useState('')
  const [roleUserId, setRoleUserId] = useState('')
  const [userToRemoveId, setUserToRemoveId] = useState('')
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false)
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState(false)
  const { t } = useTranslation()
  const [approveUserOpen, setApproveUserOpen] = useState(false)
  const [approvingId, setApprovingId] = useState('')
  const { mutate: removeUser, isPending: isRemoving } = useRemoveUser()
  const { mutate: deactivateUser, isPending: isDeactivating } = useDeactivateUser()
  const { mutate: approveUser, isPending: isApproving } = useApproveUser()
  const navigate = useNavigate()

  const handleRemoveUser = () => {
    if (!org || !userToRemoveId) return
    removeUser(
      { organization: org, id: userToRemoveId },
      {
        onSuccess: () => {
          refreshTable && refreshTable()
          setRemoveUserModalOpen(false)
          setUserToRemoveId('')
        },
      },
    )
  }

  const handleDeactivate = () => {
    if (!org || !userToRemoveId) return
    deactivateUser(
      { organization: org, id: userToRemoveId },
      {
        onSuccess: () => {
          refreshTable && refreshTable()
          setDeactivateUserModalOpen(false)
          setUserToRemoveId('')
        },
      },
    )
  }

  const { org } = useParams()

  useEffect(() => {
    setTableData(rows)
  }, [rows])

  useEffect(() => {
    if (!org) return
    UserService.listApplications(org).then((apps) => {
      apps.forEach((app) => {
        UserService.getRolesForApp(app.code).then((roles) => {
          setAppRoles((oldValue) => ({
            ...oldValue,
            [app.code]: roles,
          }))
        })
      })
    })
  }, [org])

  const handleAddRole = (roleId: string) => {
    if (roleUserId === '' || !org) return
    UserService.addAccessToApp(org, roleUserId, { appId: roleAppSelected, roleId: roleId }).then(() => {
      setAccessModalOpen(false)
      refreshTable && refreshTable()
    })
  }

  const handleRemoveRole = (id: string, appId: string) => {
    if (!org) return
    const accountId = instance.getActiveAccount()?.localAccountId
    const userId = rows.find((row) => row['id'].value === id)?.['objectId'].value
    if (accountId !== userId) {
      UserService.removeAccessFromApp(org, id, { appId }).then(() => refreshTable && refreshTable())
    }
  }

  const handleApproveUser = (body: ApproveUserRequest) => {
    if (!org || !approvingId) return
    approveUser(
      { organization: org, id: approvingId, body: body },
      {
        onSuccess: () => {
          refreshTable && refreshTable()
          setApproveUserOpen(false)
          setApprovingId('')
        },
      },
    )
  }

  function checkShouldRemoveUser(row) {
    //user can't remove or deactivate himself
    if (row.objectId.value === instance.getActiveAccount()?.localAccountId) return false

    //admin can't remove or deactivate org admin
    const loggedInUser = tableData.find((el) => el.objectId.value === instance.getActiveAccount()?.localAccountId)
    const myRole = loggedInUser?.appRoles.value.find((el) => el.id === 's-organization')
    const rowRole = row.appRoles.value.find((el) => el.id === 's-organization')
    if (myRole?.roleCode === 'admin' && rowRole?.roleCode === 'org-admin') {
      return false
    } else return true
  }

  const actionDict = (status: string, id: string) => {
    switch (status) {
      case 'pending approval':
        return {
          type: 'linkText',
          value: t('Users.approve'),
          onClick: () => {
            setApproveUserOpen(true)
            setApprovingId(id)
          },
        } as TableField
      case 'invitation expired':
        return {
          type: 'linkText',
          value: t('Users.resendInvite'),
          onClick: () => {
            if (!org) return
            UserService.resendInvite(org, id).then(() => refreshTable && refreshTable())
          },
        } as TableField
      case 'active':
        return {
          type: 'linkText',
          value: t('Users.profile'),
          onClick: () => {
            const userId = rows.find((row) => row['id'].value === id)?.['objectId'].value
            navigate(`/${org}/profile/${userId}`)
          },
        } as TableField
      case 'inactive':
        return {
          type: 'linkText',
          value: t('Users.activate'),
          onClick: () => {
            if (!org) return
            UserService.activateUser(org, id).then(() => refreshTable && refreshTable())
          },
        } as TableField
      case 'invitation sent':
        return {
          type: 'plainText',
          value: '',
        } as TableField
      default:
        return {
          type: 'plainText',
          value: status,
        } as TableField
    }
  }
  const menuActions = (status: string, id: string) => {
    switch (status) {
      case 'pending approval':
        //reject, remove user, profile
        return [
          {
            name: t('Users.reject'),
            onClick: () => {
              if (!org) return
              UserService.rejectUser(org, id).then(() => refreshTable && refreshTable())
            },
          },
          {
            name: t('Users.removeUser'),
            onClick: () => {
              setRemoveUserModalOpen(true)
              setUserToRemoveId(id)
            },
          },
          {
            name: t('Users.profile'),
            onClick: () => {
              const userId = rows.find((row) => row['id'].value === id)?.['objectId'].value
              navigate(`/${org}/profile/${userId}`)
            },
          },
        ] as TableAction[]
      case 'invitation expired':
        return [
          {
            name: t('Users.removeUser'),
            onClick: () => {
              setRemoveUserModalOpen(true)
              setUserToRemoveId(id)
            },
          },
        ] as TableAction[]
      case 'invitation sent':
        return [] as TableAction[]
      case 'active':
        return [
          {
            name: t('Users.deactivate'),
            onClick: () => {
              setDeactivateUserModalOpen(true)
              setUserToRemoveId(id)
            },
          },
          {
            name: t('Users.removeUser'),
            onClick: () => {
              setRemoveUserModalOpen(true)
              setUserToRemoveId(id)
            },
          },
        ] as TableAction[]
      default:
        return [] as TableAction[]
    }
  }

  return (
    <>
      <table className='min-w-full divide-y divide-gray-200 border-collapse'>
        <thead className='text-sm bg-white'>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                data-testid='table-header'
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-normal text-slate-600 capitalize tracking-wider cursor-pointer'
              >
                <div className='inline-flex items-center justify-start gap-x-3 '>{header.label}</div>
              </th>
            ))}
          </tr>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-medium text-gray-500 min-w-[12rem] tracking-wider'
                data-testid='filter'
              >
                {header.searchType === 'search' ? (
                  <div>
                    <TableHeaderSearchCobobox
                      searchOptions={
                        header.options?.map((h) => {
                          return {
                            type: 'plainText',
                            value: h,
                          }
                        }) ?? []
                      }
                      searchValue={filterValues[header.accessor]}
                      onSearch={(val) => onHeaderFilter?.(header.accessor, val)}
                    />
                  </div>
                ) : header.searchType === 'select' ? (
                  <div>
                    <TableHeaderCombobox
                      options={
                        header.options?.map((h) => {
                          if (typeof h === 'string') {
                            return {
                              type: 'plainText',
                              value: h,
                            }
                          } else if (typeof h === 'object' && h.id) {
                            return {
                              type: 'plainText',
                              id: h.id,
                              value: h.status,
                            }
                          } else {
                            return {
                              type: 'plainText',
                              value: '',
                            }
                          }
                        }) ?? []
                      }
                      value={filterValues[header.accessor] ?? []}
                      selectFor={''}
                      onChange={(val) => onHeaderFilter && onHeaderFilter(header.accessor, val)}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-white'>
          {tableData?.map((row, index) => (
            <tr key={index} className='border-b border-gray-300' data-testid='row'>
              {headers?.map((header, index) => {
                const field = header.accessor === 'action' ? row['status'] : row[header.accessor]
                if (header.accessor === 'action') {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <DataField
                        field={actionDict(field.value.toLocaleLowerCase(), row['id'].value)}
                        inactive={row['status'].value === 'Inactive'}
                      />
                    </td>
                  )
                } else {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <DataField
                        field={field}
                        inactive={row['status'].value != 'Active' || !checkShouldRemoveUser(row)}
                        onClick={(value) => {
                          if (field.type === 'checkbox') {
                            if (value) {
                              setRoleUserId(row['id'].value)
                              setRoleAppSelected(header.accessor)
                              setAccessModalOpen(true)
                            } else {
                              handleRemoveRole(row['id'].value, header.accessor)
                            }
                          }
                        }}
                        clickable={row.objectId.value !== instance.getActiveAccount()?.localAccountId}
                      />
                    </td>
                  )
                }
              })}
              <td className='p-3'>
                {checkShouldRemoveUser(row) &&
                  menuActions(row['status']['value'].toLocaleLowerCase(), row['id'].value).length > 0 && (
                    <Menu as='div' className='relative inline-block text-left'>
                      <div className=''>
                        <Menu.Button
                          className='inline-flex items-center w-full'
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <EllipsisVerticalIcon className='w-6 h-6 text-black hover:text-slate-400' />
                        </Menu.Button>
                      </div>
                      <Menu.Items className='absolute bg-white z-10 right-0 mt-1 w-56 origin-top-right rounded drop-shadow-lg shadow-lg'>
                        <div className='py-1'>
                          {menuActions(row['status']['value'].toLocaleLowerCase(), row['id'].value)?.map(
                            (action, index) => (
                              <Menu.Item key={`action-${index}`}>
                                <button
                                  className='border-none bg-white flex items-center py-2 px-4 w-full hover:bg-sky-50'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    action.onClick(row)
                                  }}
                                >
                                  {action.name}
                                </button>
                              </Menu.Item>
                            ),
                          )}
                        </div>
                      </Menu.Items>
                    </Menu>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {accessModalOpen && appRoles && roleAppSelected !== '' && (
        <RolesModal
          open={accessModalOpen}
          setOpen={setAccessModalOpen}
          app={roleAppSelected}
          roles={appRoles[roleAppSelected]}
          onOK={handleAddRole}
        />
      )}
      {approveUserOpen && (
        <ApproveUserModal
          open={approveUserOpen}
          setOpen={setApproveUserOpen}
          onApprove={handleApproveUser}
          isLoading={isApproving}
        />
      )}
      {removeUserModalOpen && (
        <DeleteModal
          title={t('Users.removeUser')}
          description={t('Users.removeUserMsg')}
          open={removeUserModalOpen}
          setOpen={setRemoveUserModalOpen}
          onDeleteConfirmed={handleRemoveUser}
          actionLabel={t('Users.remove')!}
          isLoading={isRemoving}
        />
      )}
      {deactivateUserModalOpen && (
        <DeleteModal
          title={t('Users.deactivateUser')}
          description={t('Users.deactivateUserMsg')}
          open={deactivateUserModalOpen}
          setOpen={setDeactivateUserModalOpen}
          onDeleteConfirmed={handleDeactivate}
          actionLabel={t('Users.deactivate')!}
          isLoading={isDeactivating}
        />
      )}
    </>
  )
}

export default Table
