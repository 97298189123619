import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GroupMembersResponse } from '../../models/Group'
import GroupService from '../../services/GroupService'

export const useGroupMembers = (groupId?: string) => {
  const { org } = useParams()
  return useQuery<GroupMembersResponse>({
    queryKey: ['group-members', org, groupId],
    queryFn: () => GroupService.listMembers(org!, groupId!),
    enabled: !!org && !!groupId,
  })
}
