import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Button } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Group } from '../../models/Group'
import { usePaginatedGroups } from '../../repositories/groups/groups-paginated.repository'
import { useTranslation } from 'react-i18next'

interface MembersHeaderProps {
  onAdMembers?(): void
}
const MembersHeader: FC<MembersHeaderProps> = ({ onAdMembers }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [group, setGroup] = useState<Group | null>(null)
  const { data: groupPages, fetchNextPage, hasNextPage } = usePaginatedGroups({ size: 20, search: '' })
  const { t } = useTranslation()

  useEffect(() => {
    if (!id || group) return
    const extractGroup = (groups: Group[]) => {
      const temp = groups.find((g) => g.id === id)
      setGroup(temp ?? null)
      return !!temp
    }
    const groups = groupPages?.pages.at(-1)?.results ?? []
    const found = extractGroup(groups)
    if (found || !hasNextPage) return
    fetchNextPage()
  }, [fetchNextPage, group, groupPages, hasNextPage, id])

  return (
    <div className='flex flex-none items-center gap-x-16 border-b border-gray-300 pr-8 pl-4 py-3'>
      <div className='flex items-center gap-x-6'>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='w-5 h-5 text-gray-500' />
        </Button>
        <p className='text-2xl'>
          {t('groups')} / {group?.name}
        </p>
      </div>
      <div className='flex items-center gap-x-4'>
        <Button className='text-white bg-blue-500 border border-blue-500' onClick={onAdMembers}>
          {t('Users.addMembers')}
        </Button>
      </div>
    </div>
  )
}

export default MembersHeader
