import React, { FC } from 'react'

const BackIcon: FC = () => {
  return (
    <svg
      className='cursor-pointer ml-[-7px]'
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='38' height='38' rx='19' fill='white' />
      <path d='M17 27L18.41 25.59L12.83 20L29 20L29 18L12.83 18L18.42 12.42L17 11L9 19L17 27Z' fill='#8087A0' />
    </svg>
  )
}

export default BackIcon
