import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type UpdateFieldPayload = {
  organization: string
  id: string
  name: string
}

export const useUpdateField = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateFieldPayload>({
    mutationKey: ['update-custom-field'],
    mutationFn: ({ organization, id, name }) => OrganizationChartService.updateField(organization, id, name),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['custom-fields', organization] })
    },
  })
}
