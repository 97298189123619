import React, { FC } from 'react'

const RadioItem: FC<{ value: string; onChange?(value: string): void; option: string }> = ({
  value,
  onChange,
  option,
}) => {
  return (
    <label htmlFor={`radio-button-${option}`} className='flex items-center gap-x-3 cursor-pointer '>
      <input
        type='radio'
        name={'options'}
        id={`radio-button-${option}`}
        value={option}
        checked={option === value}
        className={'hidden focus:outline-none focus:ring-0 focus:ring-offset-0 border-2 border-gray-300 w-4 h-4'}
        onChange={(e) => onChange?.(e.target.value)}
      />
      <span
        className={`w-5 h-5 rounded-full border-2 border-gray-300 ${
          option === value ? '!bg-sky-500 !border-sky-500 !ring-white ring ring-inset' : ''
        }`}
      />
    </label>
  )
}

export default RadioItem
