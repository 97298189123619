import React, { FC, useCallback, useEffect, useState } from 'react'
import OrgMembersHeader from './OrgMembersHeader'
import OrgMemberTab from './OrgMemberTab'
import { useNavigate, useParams } from 'react-router-dom'
import AddOrgMembersModal from './AddOrgMembersModal'
import { useOrgChartMembers } from '../../repositories/organization-nodes/organization-nodes-members.repository'
import { DeleteModal } from '@sistemiv/s-components'
import { useRemoveOrgMembers } from '../../repositories/organization-nodes/mutations/organization-node-remove-member'
import { useAddOrgMembers } from '../../repositories/organization-nodes/mutations/organization-nodes-add-members.mutation'
import { OrgNode } from '../../models/OrganizationalNode'
import UserService from '../../services/UserService'
import { useTranslation } from 'react-i18next'

const OrgMembersPage: FC = () => {
  const { org, id } = useParams()
  const [removeMemberOpen, setRemoveMemberOpen] = useState(false)
  const [removingMemberId, setRemovingMemberId] = useState('')
  const [addMembersOpen, setAddMembersOpen] = useState(false)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const {
    data: membersPages,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useOrgChartMembers({ id, nodeMembers: true, search })
  const { mutate: removeOrgMember } = useRemoveOrgMembers()
  const { mutate: addOrgMembers, isPending: adding } = useAddOrgMembers()
  const handleUserSearch = useCallback((query: string) => {
    setSearch(query)
  }, [])
  const { t } = useTranslation()
  const [orgNodes, setOrgNodes] = useState<OrgNode[]>([])
  const [selectedOrg, setSelectedOrg] = useState<OrgNode | null>(null)

  useEffect(() => {
    if (!org) return
    UserService.listOrgNodes(org, 0, 20).then((response) => {
      if (response.total > 20) {
        UserService.listOrgNodes(org, 0, response.total).then((r) => setOrgNodes(r.results))
      }
      setOrgNodes(response.results)
    })
  }, [org])

  useEffect(() => {
    setSelectedOrg(orgNodes.find((org) => org.id === id) ?? null)
  }, [id, orgNodes])

  const handleUserDelete = (id: string) => {
    setRemovingMemberId(id)
    setRemoveMemberOpen(true)
  }

  const handleAddMembers = (users: any[]) => {
    if (!org || !id) return
    addOrgMembers({ organization: org, id: id, users }, { onSuccess: () => setAddMembersOpen(false) })
  }

  useEffect(() => {
    if (hasNextPage) fetchNextPage
  }, [hasNextPage, fetchNextPage, membersPages])

  const items =
    membersPages?.pages
      .flat(Infinity)
      .map((r) => r.users)
      .flat()
      .map((member) => ({ id: member.id, name: member.name, objectId: member.objectId })) ?? []
  return (
    <div>
      <OrgMembersHeader onAddMembers={() => setAddMembersOpen(true)} selectedOrg={selectedOrg} />
      <div className='p-8'>
        <OrgMemberTab
          items={items}
          linkName={t('Users.profile')}
          onDelete={handleUserDelete}
          onSearch={handleUserSearch}
          fetching={isLoading || isFetching}
          selectedOrg={selectedOrg}
          onLinkClick={(id) => {
            navigate(`/${org}/profile/${id}`)
          }}
        />
      </div>
      {addMembersOpen && (
        <AddOrgMembersModal
          open={addMembersOpen}
          setOpen={setAddMembersOpen}
          onAdd={handleAddMembers}
          adding={adding}
        />
      )}
      {removeMemberOpen && (
        <DeleteModal
          title={t('Users.removeUser')}
          description={t('OrgChart.removeFromPositionMsg')}
          actionLabel={t('Users.remove') as string}
          open={removeMemberOpen}
          setOpen={setRemoveMemberOpen}
          onDeleteConfirmed={() => {
            if (!org || !id || removingMemberId === '') return
            removeOrgMember(
              { organization: org, id, memberId: removingMemberId },
              {
                onSuccess: () => setRemoveMemberOpen(false),
              },
            )
          }}
        />
      )}
    </div>
  )
}

export default OrgMembersPage
