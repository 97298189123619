import React, { useState, FC } from 'react'
import { Combobox, Popover } from '@headlessui/react'
import { TableField } from '../table/Table'
import { ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import DataField from './DataField'
import { Button } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'

type TableHeaderComboboxProps = {
  options: TableField[]
  value: TableField[]
  selectFor: string
  onChange?: (value?: TableField[]) => void
}

const TableHeaderCombobox: FC<TableHeaderComboboxProps> = ({
  options,
  value,
  onChange,
  selectFor,
}: TableHeaderComboboxProps) => {
  const [query, setQuery] = useState<string>('')
  const { t } = useTranslation()

  const filteredData =
    query === ''
      ? options
      : options.filter((option) => {
          return option.value.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <div data-testid='multi-select-search'>
      <Popover>
        <div className='relative w-full'>
          <Popover.Button
            className={`relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left border border-gray-300
              ${value.length > 0 && value.length < options.length ? 'bg-blue-50' : 'bg-white'}`}
          >
            <span className='flex gap-x-2 text-sm font-normal text-slate-500'>
              {value.length > 0 ? (
                value.length < options.length ? (
                  <DataField field={value[0]} className='overflow-hidden whitespace-nowrap max-w-[8rem]' />
                ) : (
                  <p>{`${t('Users.all')} ${selectFor}`}</p>
                )
              ) : (
                <p>{`${t('Users.select')} ${selectFor}`}</p>
              )}
              {value.length > 1 && value.length < options.length && (
                <div className='rounded-full text-white bg-sky-400 px-1 py-0'>+{value.length - 1}</div>
              )}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
              {value.length === 0 || value.length === options.length ? (
                <ChevronDownIcon className='w-4 h-4 text-gray-500' />
              ) : (
                <XMarkIcon className='cursor-pointer w-4 h-4 text-gray-600' onClick={() => onChange && onChange([])} />
              )}
            </span>
          </Popover.Button>
          <Popover.Panel
            className={
              'absolute z-10 mt-1 bg-white rounded-md max-h-60 overflow-auto shadow-md drop-shadow-md ring-1 ring-black ring-opacity-5'
            }
          >
            <Combobox
              value={value?.map((o) => o.id)}
              onChange={(value) => onChange && onChange(options.filter((f) => value.includes(f.id ?? '')))}
              multiple
            >
              <div className='relative'>
                <div className='relative w-full border-b border-gray-300 cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-0 sm:text-sm'>
                  <Combobox.Input
                    className={'w-full border-none py-2 pl-10 pr-3 text-sm leading-5 text-gray-900 focus:ring-0'}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={t('Users.search')}
                    name='table-grid-filter-input'
                  />
                  <Combobox.Button className='absolute inset-y-0 left-0 flex items-center pl-2'>
                    <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                  </Combobox.Button>
                </div>
                <Combobox.Options
                  className={'relative max-h-60 w-full py-1 text-base focus:outline-none sm:text-sm'}
                  static
                >
                  <div className='flex justify-between items-center'>
                    <Button
                      className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                      onClick={() => onChange && onChange(filteredData)}
                      disabled={value?.length === filteredData?.length}
                    >
                      {t('Users.selectAll')}
                    </Button>
                    <Button
                      className='text-blue-500 disabled:text-gray-400 whitespace-nowrap'
                      onClick={() => onChange && onChange([])}
                      disabled={value?.length === 0}
                    >
                      {t('Users.clearSelection')}
                    </Button>
                  </div>
                  {filteredData?.length === 0 && query !== '' ? (
                    <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                      {t('Users.nothingFound')}
                    </div>
                  ) : (
                    filteredData?.map((option, index) => (
                      <Combobox.Option
                        key={`option-${index}`}
                        value={option.id}
                        className='relative cursor-pointer select-none py-2 px-4 text-gray-900'
                      >
                        {({ selected }) => (
                          <div className='flex items-center justify-start gap-x-4'>
                            <input
                              type='checkbox'
                              readOnly
                              checked={selected}
                              className={
                                'cursor-pointer h-4.5 w-4.5 text-sky-500 rounded-sm disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0'
                              }
                            />
                            <DataField
                              field={option}
                              className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[15rem]'
                            />
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </div>
            </Combobox>
          </Popover.Panel>
        </div>
      </Popover>
    </div>
  )
}

export default TableHeaderCombobox
