import { useMutation, useQueryClient } from '@tanstack/react-query'
import GroupService from '../../../services/GroupService'
import { useParams } from 'react-router-dom'

type RemoveMemberGroupPayload = {
  organization: string
  groupId: string
  itemId: string
}

export const useRemoveMemberFromGroup = () => {
  const { org } = useParams()
  const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveMemberGroupPayload>({
    mutationKey: ['group-remove-member'],
    mutationFn: ({ organization, groupId, itemId }) => GroupService.deleteMember(organization, groupId, itemId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['group-non-members', org] })
      queryClient.invalidateQueries({ queryKey: ['group-members', org] })
    },
  })
}
