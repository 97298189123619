import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { GroupResponse } from '../../models/Group'

type GroupsOptions = {
  page: number
  size: number
  search?: any
}

export const useGroups = ({ page, size, search }: GroupsOptions) => {
  const { org } = useParams()
  return useQuery<GroupResponse>({
    queryKey: ['groups', org, { page, size, search }],
    queryFn: () => UserService.listGroups(org!, page - 1, size, search ?? undefined),
    placeholderData: keepPreviousData,
    enabled: !!org,
    staleTime: 0,
  })
}
