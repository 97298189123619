import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type UpdateOrgPositionPayload = {
  organization: string
  nodeId: string
  name: string
}

export const useUpdateOrgPosition = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateOrgPositionPayload>({
    mutationKey: ['updateOrgPosition'],
    mutationFn: ({ organization, nodeId, name }) => OrganizationChartService.update(organization, nodeId, { name }),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
