import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { Roles } from '../../models/Roles'

type ApplicationsRolesType = {
  app?: string
}

export const useApplicationRoles = ({ app }: ApplicationsRolesType) => {
  return useQuery<Roles>({
    queryKey: ['application-roles', app],
    queryFn: () => UserService.getRolesForApp(app!),
    enabled: !!app,
  })
}
