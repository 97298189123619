import React, { FC, useCallback, useEffect, useState } from 'react'
import Table, { TableHeaders, TableRow } from '../table/Table'
import TableHeader from '../table-header/TableHeader'
import { InviteUserRequest, OverviewUserResponse, Status } from '../../models/User'
import { useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { FieldValueResponse } from '../../models/Field'
import { useApplications } from '../../context/ApplicationsContext'
import InviteUsersModal from '../invite-user-modal/InviteUserModal'
import { SpinnerIcon } from '@sistemiv/s-components'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import { useInviteUser } from '../../repositories/users/mutations/invite-user.mutation'
import { refreshUsersEvents, SignalRContext } from '../../pages/Dashboard'

const UsersPage: FC = () => {
  const { org } = useParams()
  const [users, setUsers] = useState<OverviewUserResponse | null>(null)
  const [tableFilters, setTableFilters] = useState<{ [key: string]: any }>({
    groups: [],
    orgPositions: [],
    apps: [],
  })
  const [rows, setRows] = useState<TableRow[]>([])
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)
  const [statuses, setStatuses] = useState<Status[]>([])
  const [emails, setEmails] = useState<FieldValueResponse | null>(null)
  const [names, setNames] = useState<FieldValueResponse | null>(null)
  const { applications } = useApplications()
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const { t } = useTranslation()
  const { mutate: inviteUserMutation, isPending: invitingUser } = useInviteUser()
  //TODO: switch to useQuery like it is done on GroupsPage.tsx
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading4, setLoading4] = useState(false)

  const firstHeaders = [
    {
      accessor: 'name',
      label: t('Users.name'),
      searchType: 'search',
      options: names?.values?.map((v) => v.name),
    },
    {
      accessor: 'email',
      label: t('Users.email'),
      searchType: 'search',
      options: emails?.values?.map((v) => v.name),
    },
  ]
  const lastHeaders = [
    {
      accessor: 'status',
      label: t('Users.status'),
      searchType: 'select',
      options: statuses,
    },
    {
      accessor: 'action',
      label: t('Users.actions'),
      searchType: '',
    },
  ]
  const [headers, setHeaders] = useState<TableHeaders[]>([...firstHeaders, ...lastHeaders])
  const colorForStatus = (status: string) => {
    switch (status) {
      case 'Pending approval':
        return '#E2266A'
      case 'Invitation expired':
        return '#E2266A'
      case 'Invitation sent':
        return '#00A0A6'
      case 'Inactive':
        return '#26282F'
      default:
        return '#26282F'
    }
  }
  useEffect(() => {
    const newHeaders: TableHeaders[] = []
    applications?.forEach((app) => {
      if (!newHeaders.find((h) => h.accessor === app.code)) {
        newHeaders.push({
          accessor: app.code,
          label: app.code,
          searchType: '',
        })
      }
    })
    const newRows = users?.users?.map((user) => {
      const retval: TableRow = {
        id: {
          type: 'name',
          value: user.id,
        },
        name: {
          type: 'name',
          value: user.name,
          image: `${imageBase}/${user.objectId}/76`,
        },
        email: {
          type: 'plainText',
          value: user.email,
        },
        status: {
          type: 'coloredText',
          value: user.status.status,
          color: colorForStatus(user.status.status),
        },
        objectId: {
          type: 'name',
          value: user.objectId,
        },
        appRoles: {
          type: 'app_roles',
          value: user.apps,
        },
      }

      for (const appHeader of newHeaders) {
        retval[appHeader.accessor] = {
          type: 'checkbox',
          value: appHeader.accessor,
          checked: user.apps.find((app) => app.id === appHeader.accessor) ? true : false,
        }
      }

      return retval
    })

    setHeaders([...firstHeaders, ...newHeaders, ...lastHeaders])
    setRows(newRows ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, applications])

  const fetchData = useCallback(() => {
    if (!org) return
    const groupIds = tableFilters['groups']?.map((f: any) => f.id)
    const organizationNodeIds = tableFilters['orgPositions']?.map((f: any) => f.id)
    const applicationCodes = tableFilters['apps']?.map((f: any) => f.id)
    const statusIds =
      tableFilters['status'] && tableFilters['status'].length > 0
        ? tableFilters['status']?.map((f: any) => f.id)
        : statuses?.map((f: any) => f.id)
    const email = tableFilters['email']?.value
    const name = tableFilters['name']?.value

    setLoading1(true)
    UserService.listOrganizationUsers(
      org,
      page,
      size,
      groupIds,
      organizationNodeIds,
      applicationCodes,
      statusIds,
      name,
      email,
    ).then((value) => {
      setUsers(value)
      setLoading1(false)
    })
    setLoading2(true)
    UserService.listFieldValues(
      org,
      'email',
      0,
      50,
      undefined,
      groupIds,
      organizationNodeIds,
      applicationCodes,
      statusIds,
      name,
      email,
    ).then((value) => {
      setEmails(value)
      setLoading2(false)
    })
    setLoading3(true)
    UserService.listFieldValues(
      org,
      'name',
      0,
      50,
      undefined,
      groupIds,
      organizationNodeIds,
      applicationCodes,
      statusIds,
      name,
      email,
    ).then((value) => {
      setNames(value)
      setLoading3(false)
    })
  }, [org, page, size, tableFilters, statuses])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload) => {
      if (refreshUsersEvents.includes(payload)) {
        fetchData()
      }
    },
    [],
  )

  useEffect(() => {
    if (!org) return
    setLoading4(true)
    UserService.listStatuses(org).then((response) => {
      setStatuses(response?.statuses ?? [])
      setLoading4(false)
    })
  }, [org])

  const handleInviteUser = (body: InviteUserRequest) => {
    if (!org) return
    inviteUserMutation(
      {
        organization: org,
        body: body,
      },
      {
        onSuccess: () => {
          fetchData()
          setInviteModalOpen(false)
        },
      },
    )
  }

  return (
    <div className='overflow-hidden flex flex-col h-full w-full'>
      <TableHeader
        title={t('users')}
        total={users ? users.total : 1}
        page={page + 1}
        size={size}
        onFilterChanged={(field, val) =>
          setTableFilters((old) => {
            return {
              ...old,
              [field]: val ?? [],
            }
          })
        }
        groupSelected={tableFilters['groups']}
        productSelected={tableFilters['apps']}
        positionSelected={tableFilters['orgPositions']}
        onPageChange={(page) => setPage(page - 1)}
        onSizeChange={(size) => setSize(size)}
        onInviteClicked={() => setInviteModalOpen(true)}
      />
      <div
        className={`px-8 pt-3 py-1 grow overflow-hidden ${
          loading1 || loading2 || loading3 || loading4 ? 'opacity-50' : 'opacity-100'
        }`}
      >
        <div className='w-full h-full overflow-auto relative'>
          {(loading1 || loading2 || loading3 || loading4) && (
            <div className='absolute first:w-full flex justify-center items-center pt-5'>
              <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
            </div>
          )}
          <Table
            headers={headers ?? []}
            rows={rows ?? []}
            onHeaderFilter={(field, value) =>
              setTableFilters((old) => {
                return {
                  ...old,
                  [field]: value ?? undefined,
                }
              })
            }
            refreshTable={fetchData}
            filterValues={tableFilters}
          />
        </div>
      </div>
      {inviteModalOpen && (
        <InviteUsersModal
          open={inviteModalOpen}
          setOpen={setInviteModalOpen}
          onAdd={handleInviteUser}
          inviting={invitingUser}
        />
      )}
    </div>
  )
}

export default UsersPage
