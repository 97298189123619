import React, { FC } from 'react'
import { useParams } from 'react-router'
import { UserIcon } from '@heroicons/react/24/outline'
import { useProfile } from '../../repositories/profile/profile.repository'
import { imageBase } from '../../services/http-common'
import { ImgWrapper } from '@sistemiv/s-components'

const ProfileImageWrapper: FC = () => {
  const { id } = useParams()
  const { data: profile } = useProfile({ id })
  return (
    <div className='h-[128px] flex flex-col justify-center bg-gradient-to-b from-[#E1E2EA] pl-6 pr-7 '>
      <ImgWrapper
        className='rounded-full w-20 h-20 bg-white border-2 border-sky-500'
        src={`${imageBase}/${id}/180`}
        onErrorComponent={
          <p className='rounded-full w-20 h-20 border-2 border-sky-500 bg-[#7b87ae] text-white flex items-center justify-center font-bold text-6xl'>
            {profile?.data ? profile.data.firstName?.charAt(0) : <UserIcon className='w-full h-full text-white' />}
          </p>
        }
      />
    </div>
  )
}

export default ProfileImageWrapper
