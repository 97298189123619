import { Base64SVG, ImgWrapper } from '@sistemiv/s-components'
import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC } from 'react'
import { TableField } from '../table/Table'

type DataFieldProps = {
  field: TableField
  inactive?: boolean
  onClick?: (e: any) => void
  clickable?: boolean
}

const DataField: FC<PropsWithClassName<DataFieldProps>> = ({ field, onClick, inactive, clickable }) => {
  if (field && field.value !== '') {
    switch (field.type) {
      case 'name':
        return (
          <div className='flex items-center gap-x-2 text-gray-800'>
            <ImgWrapper
              src={field.image}
              className='w-6 h-6 rounded-full'
              onErrorComponent={
                <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-7'>
                  <p>{field.value?.[0]}</p>
                </div>
              }
            />
            <p>{field.value}</p>
            {field.role?.includes('admin') && (
              <div className='text-gray-500 bg-light-gray px-2 py-1 rounded text-sm'>{field.role}</div>
            )}
          </div>
        )
      case 'circle_label':
        return (
          <div className='flex items-center gap-x-2 '>
            {field.base64image ? <Base64SVG base64image={field.base64image} /> : null}
            <p>{field.value}</p>
          </div>
        )
      case 'plainText':
        return <div className={`text-gray-800`}>{field.value}</div>
      case 'coloredText':
        return <div style={{ color: field.color }}>{field.value}</div>
      case 'linkText':
        return (
          <div>
            <p onClick={field.onClick} className='text-blue-500 hover:underline cursor-pointer'>
              {field.value}
            </p>
          </div>
        )
      case 'checkbox':
        return (
          <div className='flex items-center'>
            <input
              type='checkbox'
              readOnly={field.readOnly}
              name={field.id}
              id={field.id}
              checked={field.checked}
              value={field.value}
              onChange={() => onClick && onClick(!field.checked)}
              disabled={inactive}
              className={`${
                clickable ? 'cursor-pointer' : 'cursor-arrow'
              } mr-3 h-5 w-5 text-sky-500 rounded disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0`}
            />
          </div>
        )
      default:
        return <div></div>
    }
  }

  return <div></div>
}

export default DataField
