import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'
import { AddAccessRequest } from '../../../models/Roles'

type AddProfileRolePayload = {
  organization: string
  id: string
  body: AddAccessRequest
}

export const useAddProfileRole = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, AddProfileRolePayload>({
    mutationKey: ['addProfileRole'],
    mutationFn: ({ organization, id, body }) => UserService.addAccessToApp(organization, id, body),
    onSuccess: (_, { organization }) =>
      queryClient.invalidateQueries({ queryKey: ['profile-applications', organization] }),
  })
}
