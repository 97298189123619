import React, { FC } from 'react'
import BackIcon from './BackIcon'
import { Button } from '@sistemiv/s-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useProfile } from '../../../repositories/profile/profile.repository'
import { useTranslation } from 'react-i18next'

const Header: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: profile } = useProfile({ id })
  const { t } = useTranslation()
  return (
    <div className='h-[60px] flex items-center px-6 gap-x-12'>
      <Button onClick={() => navigate(-1)} className='!p-0'>
        <BackIcon />
      </Button>
      <p className='text-lg font-medium flex items-center gap-x-1.5'>
        <span>{t('users')}</span>
        <span>/</span>
        <span>{profile?.data.firstName + ' ' + profile?.data.lastName}</span>
      </p>
    </div>
  )
}

export default Header
