import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import { Button, ChangeEmailModal, ChangePhoneModal, EditInput, RemovePhoneModal } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { useUpdateOrganization } from '../../repositories/organizations/mutations/organization-patch.mutation'
import { useParams } from 'react-router-dom'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

type CompanyContactDetailsProps = {
  email: string
  contactName: string
  contactLastName: string
  phone: string
}

const CompanyContactDetails: FC<CompanyContactDetailsProps> = ({ phone, contactLastName, contactName, email }) => {
  const { org } = useParams()
  const { mutate: updateField } = useUpdateOrganization()
  const [changePhoneNumberOpen, setChangePhoneNumberOpen] = useState(false)
  const [changeEmailOpen, setChangeEmailOpen] = useState(false)
  const [removePhoneNumberOpen, setRemovePhoneNumberOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className='mx-8'>
      <p className='text-[color:var(--app-bar-heading,#262A38)] text-base not-italic font-medium leading-6 tracking-[-0.48px];'>
        {t('Profile.contactDetails')}
      </p>
      <div className='flex flex-col py-4 max-w-xs gap-y-4'>
        <div className='space-y-1'>
          <label className='text-gray-500'>{t('CompanyProfile.contactName')}</label>
          <EditInput
            value={contactName}
            onSave={(v) =>
              org && updateField({ organization: org, field: 'contact-first-name', body: { contactFirstName: v } })
            }
          />
        </div>

        <div className='space-y-1'>
          <label className='text-gray-500'>{t('CompanyProfile.contactLastName')}</label>
          <EditInput
            value={contactLastName}
            onSave={(v) =>
              org && updateField({ organization: org, field: 'contact-last-name', body: { contactLastName: v } })
            }
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='text-gray-500'>{t('Profile.email')}</label>
        <div className='flex items-center mb-4'>
          <span className='mr-2'>{email}</span>
          <Button className='!p-0' onClick={() => setChangeEmailOpen(true)}>
            <PencilIcon className='h-5 w-5 text-gray-500' />
          </Button>
        </div>
      </div>
      <div className='flex items-center py-2 gap-x-2'>
        <div className='space-y-1'>
          <label className='text-gray-500'>{t('CompanyProfile.phone')}</label>
          <div className='flex items-center py-2 gap-x-2'>
            {phone !== '' ? (
              <>
                <span>{phone}</span>
                <Button className='!p-0' onClick={() => setChangePhoneNumberOpen(true)}>
                  <PencilIcon className='h-5 w-5 text-gray-500' />
                </Button>
                <Button className='!p-0' onClick={() => setRemovePhoneNumberOpen(true)}>
                  <TrashIcon className='h-5 w-5 text-gray-500' />
                </Button>
              </>
            ) : (
              <>
                <Button
                  className='w-full whitespace-nowrap flex items-center gap-x-2 text-blue-500 !px-2 !py-0'
                  onClick={() => setChangePhoneNumberOpen(true)}
                >
                  <PlusIcon className='w-5 h-5' />
                  {t('CompanyProfile.addPhone')}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {changePhoneNumberOpen && (
        <ChangePhoneModal
          setOpen={setChangePhoneNumberOpen}
          open={changePhoneNumberOpen}
          phone={phone}
          onContinue={(value) => {
            if (!org) return
            updateField(
              { organization: org, field: 'contact-phone', body: { contactPhone: value } },
              { onSuccess: () => setChangePhoneNumberOpen(false) },
            )
          }}
        />
      )}
      {changeEmailOpen && (
        <ChangeEmailModal
          setOpen={setChangeEmailOpen}
          open={changeEmailOpen}
          email={email}
          onContinue={(value) => {
            if (!org) return
            updateField(
              { organization: org, field: 'contact-email', body: { contactEmail: value } },
              { onSuccess: () => setChangeEmailOpen(false) },
            )
          }}
        />
      )}
      {removePhoneNumberOpen && (
        <RemovePhoneModal
          open={removePhoneNumberOpen}
          setOpen={setRemovePhoneNumberOpen}
          onRemoveConfirmed={() => {
            if (!org) return
            updateField(
              { organization: org, field: 'contact-phone', body: { contactPhone: '' } },
              { onSuccess: () => setRemovePhoneNumberOpen(false) },
            )
          }}
        />
      )}
    </div>
  )
}

export default CompanyContactDetails
