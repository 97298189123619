import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ServiceAccountsService from '../../services/ServiceAccounts.service'

export const useCredentials = (id: string) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['credentials', org, id],
    queryFn: () => ServiceAccountsService.getCredential(org!, id),
    placeholderData: keepPreviousData,
    enabled: false,
  })
}
