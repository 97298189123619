import { Select, Option } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { useApplicationRoles } from '../../../../repositories/profile/profile-app-roles.repository'
import { ApplicationProfile } from '../../../../models/User'
import { useParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { useAddProfileRole } from '../../../../repositories/profile/mutations/profile-add-role.mutation'
import { useRemoveProfileAccess } from '../../../../repositories/profile/mutations/profile-remove-access.mutation'

const PermissionItem: FC<{ app: ApplicationProfile; readonly }> = ({ app, readonly }) => {
  const { org, id } = useParams()
  const { instance } = useMsal()
  const { data: roles } = useApplicationRoles({ app: app.id })
  const [appChecked, setAppChecked] = useState(false)
  const { mutate: addRole, isPending: isAddingRole } = useAddProfileRole()
  const { mutate: removeAccess, isPending: isRemovingAccess } = useRemoveProfileAccess()

  const handleRemoveAccess = () => {
    if (instance.getActiveAccount()?.localAccountId === id) return
    if (!org) return
    removeAccess(
      { organization: org, id: app.membershipId, body: { appId: app.id } },
      { onSuccess: () => setAppChecked(false) },
    )
  }

  const pending = isAddingRole || isRemovingAccess

  return (
    <div className={`grid grid-cols-2 gap-x-3 ${pending ? 'opacity-50' : 'opacity-100'}`}>
      <div className='flex items-center gap-x-2 py-2.5'>
        <input
          checked={app.accessible || appChecked}
          type='checkbox'
          disabled={readonly || pending}
          onChange={(e) => {
            if (app.accessible) {
              handleRemoveAccess()
            } else {
              setAppChecked(e.target.checked)
            }
          }}
          className={`${
            readonly ? 'cursor-arrow' : 'cursor-pointer'
          } h-5 w-5 text-sky-500 rounded disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0`}
        />
        <p className='capitalize'>{app.name}</p>
      </div>

      <Select
        disabled={readonly || pending}
        value={app.roles?.at(0)?.name}
        className={`capitalize ${appChecked || app.accessible ? 'opacity-100' : 'opacity-0'}`}
        onChange={(value) =>
          org && addRole({ organization: org, id: app.membershipId, body: { appId: app.id, roleId: value } })
        }
      >
        {!readonly &&
          roles?.roles
            .filter((r) => r.code !== app.roles?.at(0)?.name)
            .map((value) => (
              <Option value={value.id} key={value.id}>
                <p className='capitalize'>{value.code}</p>
              </Option>
            ))}
      </Select>
    </div>
  )
}

export default PermissionItem
