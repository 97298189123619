import { useMutation, useQueryClient } from '@tanstack/react-query'
import GroupService from '../../../services/GroupService'
import { useParams } from 'react-router-dom'
import { AddMembersRequest } from '../../../models/Group'

type AddMembertoGroupPayload = {
  organization: string
  groupId: string
  body: AddMembersRequest
}

export const useAddmemberToGroup = () => {
  const { org } = useParams()
  const queryClient = useQueryClient()

  return useMutation<any, Error, AddMembertoGroupPayload>({
    mutationKey: ['group-add-member'],
    mutationFn: ({ organization, groupId, body }) => GroupService.addMembers(organization, groupId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['group-non-members', org] })
      queryClient.invalidateQueries({ queryKey: ['group-members', org] })
    },
  })
}
