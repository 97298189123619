import React, { FC, useEffect, useState } from 'react'
import { ModalDialog, SpinnerIcon } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/cjs/types/types'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ComboboxPills } from '@sistemiv/s-components'
import { Select, Option, Button, Input } from '@sistemiv/s-components'
import EmailChips from './EmailChips'
import { InviteModalResponse, InviteUserRequest } from '../../models/User'
import { useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { Role } from '../../models/Roles'
import { OrgNode } from '../../models/OrganizationalNode'
import GroupPillCombobox from '../groups/GroupPillCombobox'
import { useTranslation } from 'react-i18next'

type InviteUsersModalProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onAdd?: (value: InviteUserRequest) => void
  inviting: boolean
}

const InviteUsersModal: FC<InviteUsersModalProps> = ({ open, setOpen, onAdd, inviting }) => {
  const { org } = useParams()
  const [emailAddresses, setEmailAddresses] = useState<string[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const [orgPositions, setOrgPositions] = useState<OrgNode[]>([])
  const [inviteModalData, setInviteModalData] = useState<InviteModalResponse | null>(null)
  const [selectedAppsRole, setSelectedAppRole] = useState<{ [key: string]: Role | undefined }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!org) return
    setLoading(true)
    UserService.fetchInviteModal(org).then(setInviteModalData)
    setLoading(false)
  }, [org])

  const inviteDisabled = () => {
    if (emailAddresses.length < 1 || orgPositions.length < 1 || Object.keys(selectedAppsRole).length < 1) {
      return true
    } else {
      return false
    }
  }

  const handleInvite = () => {
    if (!onAdd) return
    const body: InviteUserRequest = {
      emailAddresses: emailAddresses,
      groupIds: groups.map((g) => g.id),
      nodeId: orgPositions[0].id,
      roles: Object.keys(selectedAppsRole)?.map((key) => ({
        roleId: selectedAppsRole[key]!.id,
        applicationId: key,
      })),
    }
    onAdd(body)
  }
  return (
    <ModalDialog isOpen={open} setOpen={() => null} className='!max-w-lg '>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between items-center'>
        {t('Users.inviteUsers')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='space-y-6 mt-5'>
        {loading ? (
          <div className='w-full flex justify-center '>
            <SpinnerIcon className='w-8 h-8 text-sky-500' />
          </div>
        ) : (
          <>
            <EmailChips
              value={emailAddresses}
              placeholder={t('Users.emailAddresses') as string}
              onChange={setEmailAddresses}
            />
            <GroupPillCombobox
              label={t('Groups.addToGroups') as string}
              value={groups}
              onChange={(value: any) => setGroups(value)}
              options={inviteModalData?.groups ?? []}
            />
            <ComboboxPills
              label={t('Groups.addToOrgPosition') as string}
              value={orgPositions?.map((op) => op.name)}
              onChange={(value) => {
                if (value.length === 1) {
                  setOrgPositions(value?.map((v) => inviteModalData?.nodes.find((n) => n.name === v)))
                } else if (value.length === 0) {
                  setOrgPositions([])
                }
              }}
              options={inviteModalData?.nodes?.map((node) => node.name) ?? []}
            />

            {inviteModalData?.applications?.map((application) => (
              <div key={application.id} className='grid grid-cols-2 items-center'>
                <Input
                  id={application.id}
                  type='checkbox'
                  label={application.id}
                  checked={application.id in selectedAppsRole}
                  onChange={(e) => {
                    if (e.target.checked && !(application.id in selectedAppsRole)) {
                      setSelectedAppRole((old) => ({ ...old, [application.id]: application.roles.at(0) }))
                    } else if (!e.target.checked && application.id in selectedAppsRole) {
                      setSelectedAppRole((old) => {
                        const copy = { ...old }
                        delete copy[application.id]
                        return copy
                      })
                    }
                  }}
                  mainClasses='capitalize'
                />
                {application.id in selectedAppsRole && (
                  <Select
                    className='capitalize'
                    value={selectedAppsRole[application.id]?.code.replaceAll('-', ' ')}
                    onChange={(value) => {
                      setSelectedAppRole((old) => ({
                        ...old,
                        [application.id]: application.roles.find((r) => r.id === value),
                      }))
                    }}
                  >
                    {application.roles?.map((role) => (
                      <Option key={role.id} value={role.id}>
                        <p className='capitalize'>{role.code.replaceAll('-', ' ')}</p>
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
            ))}
          </>
        )}

        <div className='flex justify-end mt-5'>
          <Button className='mr-3 hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
            {t('Users.cancel')}
          </Button>
          <Button
            className='mr-3 bg-blue-500 disabled:bg-slate-300 text-white'
            onClick={handleInvite}
            disabled={inviteDisabled()}
            loading={inviting}
          >
            {t('Users.invite')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default InviteUsersModal
