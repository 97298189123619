import { useInfiniteQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import UserService from '../../services/UserService'
import { GroupResponse } from '../../models/Group'

type PaginatedGroupsType = {
  size?: number
  search?: string
}

export const usePaginatedGroups = (payload: PaginatedGroupsType) => {
  const { org } = useParams()
  return useInfiniteQuery<GroupResponse>({
    queryKey: ['paginated-groups', org, payload],
    queryFn: ({ pageParam }) => UserService.listGroups(org!, pageParam as number, payload.size, payload.search),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _2, lastPageParam) => {
      if (lastPage.results.length === 0) {
        return undefined
      }
      return Number(lastPageParam) + 1
    },
    enabled: !!org,
  })
}
