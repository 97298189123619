import { useInfiniteQuery } from '@tanstack/react-query'
import OrganizationChartService from '../../services/OrganizationChart.service'
import { useParams } from 'react-router-dom'
import { OrgMemberResponse } from '../../models/OrganizationalNode'

type OrganizationsOptions = {
  id?: string
  size?: number
  search?: string
  nodeMembers?: boolean
}

export const useOrgChartMembers = ({ id, ...payload }: OrganizationsOptions) => {
  const { org } = useParams()
  return useInfiniteQuery<OrgMemberResponse>({
    queryKey: ['organization-nodes-members', id, payload],
    queryFn: ({ pageParam }) =>
      OrganizationChartService.fetchMembers(
        org!,
        id!,
        pageParam as number,
        payload.size,
        payload.search,
        payload.nodeMembers,
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _2, lastPageParam) => {
      if (lastPage.users.length === 0) {
        return undefined
      }
      return Number(lastPageParam) + 1
    },
    enabled: !!org && !!id,
  })
}
