import { Profile, ProfileApplications } from '../models/User'
import http from './http-common'
class ProfileService {
  async get(id: string): Promise<Profile> {
    return (await http.get(`/profile/${id}`)).data
  }

  async getApplications(organization: string, id: string): Promise<ProfileApplications> {
    return (await http.get(`/${organization}/profile/application/user/${id}`)).data
  }

  async getProfilePicture(id: string, size: number) {
    return (await http.get(`/profile/picture/${id}/${size}`)).data
  }

  async addAppRole(organization: string, id: string, appId: string) {
    return (await http.post(`/${organization}/profile/${id}/app/${appId}`)).data
  }
}

export default new ProfileService()
