import { Button, Input, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useEffect, useState } from 'react'
import { Role, Roles } from '../../models/Roles'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

const RolesModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  app: string
  roles: Roles
  onOK(roleId: string): void
}> = ({ open, setOpen, app, roles, onOK }) => {
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(roles.roles[0])
  const { t } = useTranslation()

  const radioOptions = roles.roles?.map((role) => ({
    value: role.code,
    label: role.code.replaceAll('-', ' '),
  }))

  useEffect(() => console.log(selectedRole), [selectedRole])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-xs'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        <p className='capitalize'>
          {app.replace('-', ' ')} {t('Users.access')}
        </p>
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='mt-5'>
        <Input
          id='radio'
          type='radio'
          selectOptions={radioOptions}
          mainClasses='capitalize'
          value={selectedRole?.code}
          onChange={(e) => {
            setSelectedRole(roles.roles.find((role) => role.code === e.target.value))
          }}
        />
      </div>
      <div className='flex justify-end mt-5'>
        <Button className='mr-3' onClick={() => setOpen(false)}>
          {t('Users.cancel')}
        </Button>
        <Button className='mr-3 bg-blue-500 text-white' onClick={() => selectedRole && onOK(selectedRole.id)}>
          OK
        </Button>
      </div>
    </ModalDialog>
  )
}

export default RolesModal
