import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type RemoveOrgPositionPayload = {
  organization: string
  nodeId: string
}

export const useRemoveOrgPosition = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveOrgPositionPayload>({
    mutationKey: ['removeOrgPosition'],
    mutationFn: ({ organization, nodeId }) => OrganizationChartService.remove(organization, nodeId),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
