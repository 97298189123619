import { useMutation } from '@tanstack/react-query'
import UserService from '../../../services/UserService'

type RemoveUserPayload = {
  organization: string
  id: string
}

export const useRemoveUser = () => {
  // const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveUserPayload>({
    mutationKey: ['remove-user'],
    mutationFn: ({ organization, id }) => UserService.removeUser(organization, id),
    // onSuccess: (_, { organization }) =>
    //   queryClient.invalidateQueries({ queryKey: ['profile-applications', organization] }),
  })
}
