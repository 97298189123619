import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationService from '../../../services/Organization.service'

type UpdateOrganizationPayload = {
  organization: string
  field: string
  body: any
}

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateOrganizationPayload>({
    mutationKey: ['updateOrganizationInfo'],
    mutationFn: ({ organization, field, body }) => OrganizationService.update(organization, field, body),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['current-organization'] }),
  })
}
