import { useMutation, useQueryClient } from '@tanstack/react-query'
import ServiceAccountsService from '../../../services/ServiceAccounts.service'

type UpdateServiceAccPayload = {
  organization: string
  id: string
  name: string
}

export const useUpdateServiceAccName = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateServiceAccPayload>({
    mutationKey: ['service-acc-update-name'],
    mutationFn: (payload) => ServiceAccountsService.updateName(payload.id, payload.organization, payload.name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['service-accounts'] })
    },
  })
}
