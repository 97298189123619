import { useQuery } from '@tanstack/react-query'
import OrganizationChartService from '../../services/OrganizationChart.service'
import { useParams } from 'react-router-dom'
import { OrgPosition } from '../../components/org-charts/OrgChartsTreeView'

export const useOrganizationNodes = () => {
  const { org } = useParams()
  return useQuery<OrgPosition[]>({
    queryKey: ['organization-nodes', org],
    queryFn: () => OrganizationChartService.tree(org!),
    enabled: !!org,
  })
}
