import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type AddCustomFieldPayload = {
  organization: string
  name: string
}

export const useAddCustomField = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, AddCustomFieldPayload>({
    mutationKey: ['add-custom-field'],
    mutationFn: ({ organization, name }) => OrganizationChartService.addCustomField(organization, name),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['custom-fields', organization] })
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
