import React, { FC } from 'react'
import CompanyProfileHeaders from './CompanyProfileHeaders'
import { useCurrentOrganization } from '../../repositories/organizations/organization.respository'
import CompanyDetails from './CompanyDetails'
import CompanyAbout from './CompanyAboutSection'
import CompanyContactDetails from './CompanyContactSection'

const CompanyProfilePage: FC = () => {
  // const actions: StatusAction[] = [
  //   {
  //     id: '1',
  //     value: 'Active',
  //     onClick: () => {
  //       console.log('active')
  //     },
  //   },
  //   {
  //     id: '2',
  //     value: 'Inactive',
  //     onClick: () => {
  //       console.log('inactive')
  //     },
  //   },
  //   {
  //     id: '3',
  //     value: 'Pending',
  //     onClick: () => {
  //       console.log('pending')
  //     },
  //   },
  // ]
  const { data: organization } = useCurrentOrganization()

  return (
    <div>
      <CompanyProfileHeaders showBanner={false} />
      <div className='flex items-start gap-16 self-stretch pr-9'>
        <CompanyAbout
          organizationName={organization?.name ?? ''}
          legalCompanyName={organization?.legalName ?? ''}
          organizationCode={organization?.code ?? ''}
          //statusActions={actions}
        />
        <CompanyContactDetails
          email={organization?.contactEmail ?? ''}
          contactName={organization?.contactFirstName ?? ''}
          contactLastName={organization?.contactLastName ?? ''}
          phone={organization?.contactPhone ?? ''}
        />
        <CompanyDetails
          phone={organization?.phone ?? ''}
          address={organization?.address ?? ''}
          city={organization?.city ?? ''}
          postCode={organization?.postCode ?? ''}
          taxNumber={organization?.taxNumber ?? ''}
          country={organization?.country ?? ''}
        />
      </div>
    </div>
  )
}

export default CompanyProfilePage
