import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type ReorderOrgPositionPayload = {
  organization: string
  nodeId: string
  parentId: string | null
  position: number
}

export const useReorderOrgPosition = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, ReorderOrgPositionPayload>({
    mutationKey: ['reorderOrgPosition'],
    mutationFn: ({ organization, nodeId, parentId, position }) =>
      OrganizationChartService.updatePosition(organization, nodeId, { parentId, position }),
    onSuccess: (_, { organization }) => {
      console.log('success')
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
    onError: (_, { organization }) => {
      console.log('error')
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
