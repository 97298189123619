import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'

type ActivateProfilePayload = {
  organization: string
  id: string
}

export const useActivateProfile = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, ActivateProfilePayload>({
    mutationKey: ['activateProfile'],
    mutationFn: ({ organization, id }) => UserService.activateUser(organization, id),
    onSuccess: (_, { organization }) =>
      queryClient.invalidateQueries({ queryKey: ['profile-applications', organization] }),
  })
}
