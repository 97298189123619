import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GroupNonMembersResponse } from '../../models/Group'
import GroupService from '../../services/GroupService'

export const useGroupNonMembers = (groupId?: string) => {
  const { org } = useParams()
  return useQuery<GroupNonMembersResponse>({
    queryKey: ['group-non-members', org, groupId],
    queryFn: () => GroupService.listNonMembers(org!, groupId!),
    enabled: !!org && !!groupId,
  })
}
