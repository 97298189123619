import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const SettingsHeader: FC = () => {
  const { t } = useTranslation()
  return (
    <div className='w-full border-b border-gray-300 px-8 py-3 flex-none'>
      <h2 className='text-2xl'>{t('settings')}</h2>
    </div>
  )
}

export default SettingsHeader
