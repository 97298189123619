import { useQuery } from '@tanstack/react-query'
import ProfileService from '../../services/Profile.service'
import { ProfileApplications } from '../../models/User'

type ProfileApplicationsType = {
  organization?: string
  id?: string
}

export const useProfileApplications = ({ organization, id }: ProfileApplicationsType) => {
  return useQuery<ProfileApplications>({
    queryKey: ['profile-applications', organization, id],
    queryFn: () => ProfileService.getApplications(organization!, id!),
    enabled: !!organization && !!id,
  })
}
