import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationService from '../../../services/Organization.service'

type UpdateOrganizationPhotoPayload = {
  organization: string
  image: File
}

export const useUpdateOrganizationPhoto = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateOrganizationPhotoPayload>({
    mutationKey: ['updateOrganizationPhoto'],
    mutationFn: ({ organization, image }) => OrganizationService.updatePhoto(organization, image),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['current-organization'] }),
  })
}
