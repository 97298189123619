import React, { FC, PropsWithChildren } from 'react'
import http from '../../services/http-common'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

const ErrorHandlingInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  const authInterceptor = async (error) => {
    const data = error.response?.data
    if (error.response?.status === 404) {
      toast.error(data?.message)
    } else if (error.response?.status === 403) {
      navigate('/not-authorized')
      toast.error(data?.errorMessage ?? 'Forbidden!')
    } else {
      toast.error(data?.errorMessage ?? 'An error occurred!')
    }
    return error
  }

  http.interceptors.response.use(async (response) => response, authInterceptor)

  return <>{children}</>
}

export default ErrorHandlingInterceptor
