import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import OrganizationService from '../../services/Organization.service'
import { OrganizationDetails } from '../../models/Organization'

export const useCurrentOrganization = () => {
  const { org } = useParams()
  return useQuery<OrganizationDetails>({
    queryKey: ['current-organization', org],
    queryFn: () => OrganizationService.get(org!),
    enabled: !!org,
  })
}
