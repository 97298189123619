import React, { FC } from 'react'
import DetailItem from '../DetailItem'
import { Menu, Switch } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { ApplicationProfile } from '../../../../models/User'
import { useDeactivateProfile } from '../../../../repositories/profile/mutations/profile-deactivate.mutation'
import { useParams } from 'react-router-dom'
import { useActivateProfile } from '../../../../repositories/profile/mutations/profile-activate.mutation'
import { useRemoveUser } from '../../../../repositories/organization-nodes/mutations/remove-user'
import { DeleteModal } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import PermissionItem from './PermissionItem'

const OrganizationDetails: FC<{
  apps: ApplicationProfile[]
  status: string
  groups: string[]
  units: string[]
  readonly: boolean
}> = ({ apps, status, groups, units, readonly }) => {
  const { org } = useParams()
  const [removeUserModalOpen, setRemoveUserModalOpen] = React.useState(false)
  const { mutate: deactivateUser, isPending: isDeactivating } = useDeactivateProfile()
  const { mutate: activateUser, isPending: isActivating } = useActivateProfile()
  const { mutate: removeUser, isPending: isRemoving } = useRemoveUser()
  const { t } = useTranslation()

  const handleRemoveUser = () => {
    if (!org) return
    removeUser({ organization: org, id: apps?.[0].membershipId })
    setRemoveUserModalOpen(false)
  }

  const handleChangeStatus = (action: string) => {
    if (!org) return
    switch (action) {
      case 'Deactivate':
        deactivateUser({ organization: org, id: apps?.[0].membershipId })
        break
      case 'Activate':
        activateUser({ organization: org, id: apps?.[0].membershipId })
        break
      default:
        return
    }
  }

  const pending = isActivating || isDeactivating || isRemoving
  return (
    <div className={`flex flex-col gap-y-6 ${pending ? 'opacity-50' : 'opacity-100'}`}>
      <h1 className='text-lg font-medium text-[#262A38]'>{t('Profile.organization')}</h1>
      <div className='flex flex-col gap-y-4 p-4 rounded bg-gradient-to-b from-[#E8E9EE] to-[#F3F7FB]'>
        {apps.map((app, index) => (
          <PermissionItem key={index} app={app} readonly={readonly || status !== 'Active' || pending} />
        ))}
      </div>
      <DetailItem label={t('Profile.orgPositions')} value={units} />
      <DetailItem label={t('Profile.groups')} value={groups} />
      <div className='flex items-end gap-x-4'>
        <DetailItem label={t('Profile.status')} value={status} />
        {['Active', 'Inactive'].includes(status) && (
          <Switch
            checked={status === 'Active'}
            disabled={readonly || pending}
            onChange={() => handleChangeStatus(status === 'Active' ? 'Deactivate' : 'Activate')}
            className={`border mb-1 ${
              status === 'Active' ? 'border-blue-500' : 'border-gray-400'
            } relative inline-flex h-[12px] w-[24px] shrink-0 cursor-pointer rounded-full border-2 transition-colors 
                  duration-50 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white disabled:opacity-50
                  focus-visible:ring-opacity-75 disabled:cursor-default`}
          >
            <span
              aria-hidden='true'
              className={`${status === 'Active' ? 'translate-x-3' : 'translate-x-0'}
                                pointer-events-none inline-block h-[8px] w-[8px] transform rounded-full border ${
                                  status === 'Active' ? 'bg-blue-500' : 'bg-gray-400'
                                } shadow-lg ring-0 transition duration-50 ease-in-out`}
            />
          </Switch>
        )}
        {!readonly && (
          <Menu as='div' className='relative'>
            <Menu.Button className={'relative flex items-center justify-center'}>
              <EllipsisVerticalIcon className='h-5 w-5 text-gray-500' />
            </Menu.Button>
            <Menu.Items className='absolute mt-1 py-1 left-0 max-w-[10rem] w-screen bg-white rounded shadow-xl ring-1 ring-black ring-opacity-5'>
              {['Active', 'Inactive'].includes(status) && (
                <Menu.Item>
                  <div
                    className='bg-white px-3 py-2 hover:bg-blue-50 active:bg-blue-100 cursor-pointer'
                    onClick={() => handleChangeStatus(status === 'Active' ? 'Deactivate' : 'Activate')}
                  >
                    {status === 'Active' ? 'Deactivate' : 'Activate'}
                  </div>
                </Menu.Item>
              )}
              <Menu.Item>
                <div
                  className='bg-white px-3 py-2 hover:bg-blue-50 active:bg-blue-100 cursor-pointer'
                  onClick={() => setRemoveUserModalOpen(true)}
                >
                  {t('Users.removeUser')}
                </div>
              </Menu.Item>
            </Menu.Items>
            {removeUserModalOpen && (
              <DeleteModal
                title={t('Users.removeUser')}
                description={t('Users.removeUserMsg')}
                open={removeUserModalOpen}
                setOpen={setRemoveUserModalOpen}
                onDeleteConfirmed={handleRemoveUser}
                actionLabel={t('Users.remove') as string}
              />
            )}
          </Menu>
        )}
      </div>
    </div>
  )
}

export default OrganizationDetails
