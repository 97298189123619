import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'
import { RemoveAccessRequest } from '../../../models/Roles'

type RemoveProfileAccessPayload = {
  organization: string
  id: string
  body: RemoveAccessRequest
}

export const useRemoveProfileAccess = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveProfileAccessPayload>({
    mutationKey: ['removeProfileAccess'],
    mutationFn: ({ organization, id, body }) => UserService.removeAccessFromApp(organization, id, body),
    onSuccess: (_, { organization }) =>
      queryClient.invalidateQueries({ queryKey: ['profile-applications', organization] }),
  })
}
