import { AppIcon, ImgWrapper, NavigationBar } from '@sistemiv/s-components'
import { Menu } from '@headlessui/react'
import React, { FC, useEffect, useState } from 'react'
import AppLogo from '../../assets/AppLogo'
import { useApplications } from '../../context/ApplicationsContext'
import { useOrganizations } from '../../context/OrganizationsContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { imageBase, portalUrl } from '../../services/http-common'
import { useCurrentOrganization } from '../../repositories/organizations/organization.respository'
import { useTranslation } from 'react-i18next'
import { ArrowRightIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import AppsIcon from '../../assets/AppsIcon'

const TopNav: FC = () => {
  const { applications } = useApplications()
  const { organizations } = useOrganizations()
  const { data: currentOrg, isFetching: isCurrentOrgFetching } = useCurrentOrganization()
  const [currentOrgImgUrl, setCurrentOrgImgUrl] = useState('')
  const { instance } = useMsal()
  const { org } = useParams()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const handleLogout = async () => {
    window.location.replace(`${portalUrl}/logout`)
  }

  useEffect(() => {
    console.log('jelte ima')
    setCurrentOrgImgUrl(`${imageBase}/${currentOrg?.code}/76?p=${new Date().getMilliseconds()}`)
  }, [currentOrg, isCurrentOrgFetching])
  const locales = [
    { value: 'en', title: 'English (en)' },
    { value: 'sr', title: 'Srpski (sr)' },
  ]

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('lng', lng)
  }

  return (
    <NavigationBar className='h-[4.185rem] border-b border-gray-300 flex items-center justify-between'>
      <div className='flex items-center gap-x-2'>
        <AppLogo />
      </div>
      <div className='flex items-center gap-x-6'>
        <Menu as='div' className='relative'>
          <Menu.Button
            name='nav-language'
            className='inline-flex w-full justify-center items-center gap-x-2 px-1 py-2 rounded-md hover:bg-sky-50 active:bg-sky-100'
          >
            <GlobeAltIcon className='h-6 w-6' />
            <div data-testid='current-language'>{i18n.resolvedLanguage}</div>
          </Menu.Button>
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            {locales.map((locale) => (
              <Menu.Item key={`language-${locale.value}`} data-testid='nav-language'>
                {() => (
                  <div
                    onClick={() => handleChangeLng(locale.value)}
                    className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                    data-testid='nav-language'
                  >
                    {locale.title}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
        <Menu as='div' className='relative'>
          <Menu.Button
            className='inline-flex w-full justify-center items-center gap-x-2 p-2 rounded-full hover:bg-sky-50 active:bg-sky-100'
            name='apps-btn'
          >
            <AppsIcon />
          </Menu.Button>
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            {applications
              ?.filter((app) => app.code !== 's-organization')
              .map((app, index) => (
                <Menu.Item key={`application-${index}`}>
                  {() => (
                    <div
                      onClick={() =>
                        org ? window.location.replace(`${app.url}/${org}`) : window.location.replace(app.url)
                      }
                      className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                      data-testid='nav-app'
                    >
                      <AppIcon code={app.code} />
                    </div>
                  )}
                </Menu.Item>
              ))}
            <Menu.Item key='portal'>
              <div
                onClick={() =>
                  window.location.replace(
                    `https://portal${
                      process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                    }.sistemiv.com`,
                  )
                }
                className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                data-testid='nav-app'
              >
                <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                  <span>App Portal</span>
                  <ArrowRightIcon className='w-5 h-5 ml-2' />
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Menu>
        <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <ImgWrapper
              src={currentOrgImgUrl}
              className='w-8 h-8 rounded-full border-sky-500 border'
              onErrorComponent={
                <div
                  className={`w-8 h-8 rounded-full text-white flex items-center justify-center text-lg leading-[18px] border-sky-500 border ${
                    organizations.find((o) => o.organizationCode === org)?.icon ? '' : 'bg-[#7b87ae]'
                  }`}
                >
                  <p className='text-md'>
                    {organizations
                      .find((o) => o.organizationCode === org)
                      ?.name?.charAt(0)
                      ?.toUpperCase()}
                  </p>
                </div>
              }
            />
          </Menu.Button>
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            {organizations?.map((organization) => (
              <Menu.Item
                onClick={() => {
                  if (!org) return
                  navigate(location.pathname.replace(org, organization.organizationCode))
                }}
                key={organization.organizationCode}
                as='div'
                className='flex items-center gap-x-3 px-3 py-2 cursor-pointer hover:bg-sky-50'
              >
                <ImgWrapper
                  src={`${imageBase}/${organization.organizationCode}/76`}
                  className={`w-8 h-8 rounded-full flex items-center justify-center border ${
                    organization.organizationCode === org ? 'border-sky-500' : 'border-gray-300'
                  }`}
                  onErrorComponent={
                    <p className='text-md text-white w-8 h-8 rounded-full flex items-center justify-center bg-[#7b87ae]'>
                      {organization.name.charAt(0).toUpperCase()}
                    </p>
                  }
                />
                {organization.name}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
        <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <ImgWrapper
              src={`${imageBase}/${instance.getActiveAccount()?.localAccountId}/76`}
              className='w-8 h-8 rounded-full border-sky-500 border'
              onErrorComponent={
                <div className='bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center text-lg leading-[18px] border-sky-500 border'>
                  {instance.getActiveAccount()
                    ? (instance.getActiveAccount()?.idTokenClaims?.given_name as string)?.[0]
                    : 'D'}
                </div>
              }
            />
          </Menu.Button>
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            <Menu.Item
              as='div'
              onClick={() =>
                window.location.replace(
                  `https://identity${
                    process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                  }.sistemiv.com`,
                )
              }
            >
              <div className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'>{t('myProfile')}</div>
            </Menu.Item>
            <Menu.Item as='div'>
              <div className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100' onClick={handleLogout}>
                {t('logout')}
              </div>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </NavigationBar>
  )
}

export default TopNav
