import { OrganizationDetails } from '../models/Organization'
import http from './http-common'

class OrganizationService {
  async get(organization: string): Promise<OrganizationDetails> {
    return (await http.get(`/${organization}/details`)).data
  }

  async update(organization: string, field: string, body: any) {
    return (await http.post(`/${organization}/${field}`, body)).data
  }

  async updatePhoto(organization: string, image: File) {
    const formData = new FormData()
    formData.append('image', image)
    return (
      await http.post(`/${organization}/profile-picture`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ).data
  }
}

export default new OrganizationService()
