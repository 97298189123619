import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC } from 'react'

const DndButtonIcon: FC<PropsWithClassName> = ({ className }) => {
  return (
    <svg
      width='10'
      height='14'
      viewBox='0 0 10 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M4.16634 12.0002C4.16634 12.9168 3.41634 13.6668 2.49967 13.6668C1.58301 13.6668 0.833008 12.9168 0.833008 12.0002C0.833008 11.0835 1.58301 10.3335 2.49967 10.3335C3.41634 10.3335 4.16634 11.0835 4.16634 12.0002ZM2.49967 5.3335C1.58301 5.3335 0.833008 6.0835 0.833008 7.00016C0.833008 7.91683 1.58301 8.66683 2.49967 8.66683C3.41634 8.66683 4.16634 7.91683 4.16634 7.00016C4.16634 6.0835 3.41634 5.3335 2.49967 5.3335ZM2.49967 0.333496C1.58301 0.333496 0.833008 1.0835 0.833008 2.00016C0.833008 2.91683 1.58301 3.66683 2.49967 3.66683C3.41634 3.66683 4.16634 2.91683 4.16634 2.00016C4.16634 1.0835 3.41634 0.333496 2.49967 0.333496ZM7.49967 3.66683C8.41634 3.66683 9.16634 2.91683 9.16634 2.00016C9.16634 1.0835 8.41634 0.333496 7.49967 0.333496C6.58301 0.333496 5.83301 1.0835 5.83301 2.00016C5.83301 2.91683 6.58301 3.66683 7.49967 3.66683ZM7.49967 5.3335C6.58301 5.3335 5.83301 6.0835 5.83301 7.00016C5.83301 7.91683 6.58301 8.66683 7.49967 8.66683C8.41634 8.66683 9.16634 7.91683 9.16634 7.00016C9.16634 6.0835 8.41634 5.3335 7.49967 5.3335ZM7.49967 10.3335C6.58301 10.3335 5.83301 11.0835 5.83301 12.0002C5.83301 12.9168 6.58301 13.6668 7.49967 13.6668C8.41634 13.6668 9.16634 12.9168 9.16634 12.0002C9.16634 11.0835 8.41634 10.3335 7.49967 10.3335Z' />
    </svg>
  )
}

export default DndButtonIcon
