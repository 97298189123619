import { useMutation, useQueryClient } from '@tanstack/react-query'
import GroupService from '../../../services/GroupService'

type RemoveGroupMemberPayload = {
  organization: string
  id: string
  memberId: string
  onSuccess?: () => void
}

export const useRemoveGroupMember = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveGroupMemberPayload>({
    mutationKey: ['removeGroupMember'],
    mutationFn: ({ organization, id, memberId }) => GroupService.deleteMember(organization, id, memberId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}
