import React, { FC, useState } from 'react'
import { PlusIcon, PencilIcon } from '@heroicons/react/24/outline'
import { Button, DeleteModal } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { CustomField } from '../../../models/OrganizationalNode'
import { useParams } from 'react-router-dom'
import EditFieldModal from './EditFieldModal'
import AddFieldModal from './AddFieldModal'
import { ThrashBinIcon } from '@sistemiv/s-components'
import { useAddCustomField } from '../../../repositories/custom-fields/mutations/custom-fields-add.mutation'
import { useRemoveField } from '../../../repositories/custom-fields/mutations/custom-fields-remove.mutation'
import { useUpdateField } from '../../../repositories/custom-fields/mutations/custom-fields-update.mutation'

interface FieldsProps {
  data?: CustomField[]
}
const CustomFields: FC<FieldsProps> = ({ data }) => {
  const { org } = useParams()
  const { t } = useTranslation()
  const [addFieldModalOpen, setAddFieldModalOpen] = useState(false)
  const [editingField, setEditingField] = useState<CustomField | null>(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { mutate: addField, isPending: isAdding } = useAddCustomField()
  const { mutate: removeField, isPending: isRemoving } = useRemoveField()
  const { mutate: updateField } = useUpdateField()

  const handleAddField = (name: string) => {
    if (!org) return
    addField(
      { organization: org, name },
      {
        onSuccess: () => setAddFieldModalOpen(false),
      },
    )
  }

  const handleEdit = (name: string) => {
    if (!org || !editingField) return
    updateField(
      { organization: org, id: editingField.id, name },
      {
        onSuccess: () => setEditModalOpen(false),
      },
    )
  }

  const handleDelete = () => {
    if (!org || !editingField) return
    removeField(
      { organization: org, id: editingField?.id },
      {
        onSuccess: () => setDeleteModalOpen(false),
      },
    )
  }

  return (
    <div className='p-8'>
      <Button
        onClick={() => setAddFieldModalOpen(true)}
        className='text-blue-500 border border-blue-500 hover:bg-slate-50 active:!bg-slate-100 flex items-center gap-x-2'
      >
        <PlusIcon className='w-4 h-4 flex-none' />
        <>{t('OrgChart.field')}</>
      </Button>

      <div className='py-8 w-1/2'>
        {!data || (data?.length === 0 && <div>{t('OrgChart.noCustomFields')}</div>)}
        {data &&
          data.map((field) => (
            <div className={`flex group items-center hover:bg-sky-50 py-2 px-4 w-full `} key={field.id}>
              <div className='grow grid grid-flow-col gap-4'>
                <div className='whitespace-nowrap text-ellipsis overflow-hidden min-w-24 w-48 min-[1650px]:w-80'>
                  {field.key}
                </div>
                <div className='flex items-center justify-end align-end gap-x-4 opacity-0 group-hover:opacity-100'>
                  <Button
                    className='!p-0'
                    onClick={() => {
                      setEditingField(field)
                      setEditModalOpen(true)
                    }}
                  >
                    <PencilIcon className='w-4 h-4 text-gray-500 hover:text-gray-800' />
                  </Button>
                  <Button
                    className='!p-0'
                    onClick={() => {
                      setEditingField(field)
                      setDeleteModalOpen(true)
                    }}
                  >
                    <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
      {addFieldModalOpen && (
        <AddFieldModal
          open={addFieldModalOpen}
          setOpen={setAddFieldModalOpen}
          onAdd={handleAddField}
          loading={isAdding}
        />
      )}
      {editModalOpen && editingField && (
        <EditFieldModal open={editModalOpen} setOpen={setEditModalOpen} field={editingField} onEdit={handleEdit} />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('OrgChart.deleteField')}
          description={t('OrgChart.deleteFieldMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDelete}
          isLoading={isRemoving}
        />
      )}
    </div>
  )
}

export default CustomFields
