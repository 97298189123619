import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import PermissionsSettingsService, { ToggleType } from '../../../services/PermissionsSettings.service'

type TogglePermissionOptions = {
  rbacId: string
  fn: ToggleType
}

export const useTogglePermission = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, TogglePermissionOptions>({
    mutationKey: ['togglePermission'],
    mutationFn: (payload) => PermissionsSettingsService.togglePermission(payload.rbacId, payload.fn, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['permissions', org] })
    },
  })
}
