import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationChartService from '../../../services/OrganizationChart.service'

type RemoveFieldPayload = {
  organization: string
  id: string
}

export const useRemoveField = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, RemoveFieldPayload>({
    mutationKey: ['remove-field'],
    mutationFn: ({ organization, id }) => OrganizationChartService.removeField(organization, id),
    onSuccess: (_, { organization }) => {
      queryClient.invalidateQueries({ queryKey: ['custom-fields', organization] })
      queryClient.invalidateQueries({ queryKey: ['organization-nodes', organization] })
    },
  })
}
