import { Input, SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { usePermissions, useTogglePermission } from '../../repositories/permissions'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const PermissionsTab: FC = () => {
  const [headerData, setHeaderData] = useState<string[]>([])
  const { data: list = [], isLoading } = usePermissions({ applicationId: 's-organization' })
  const { mutateAsync: togglePermissionMutation, isPending: isToggling } = useTogglePermission()
  const [data, setData] = useState<any[]>([])
  const { t } = useTranslation()

  const handleTogglePermission = (item: { rbacId: string; hasPermission: boolean }) => {
    item.hasPermission = !item.hasPermission
    togglePermissionMutation(
      { rbacId: item.rbacId, fn: !item.hasPermission ? 'remove' : 'add' },
      {
        onSuccess: () => {
          toast.success(t('Settings.permissionSuccess'))
        },
      },
    )
  }

  useEffect(() => {
    if (!list.length) return
    const sections: { [key: string]: any } = {}
    const headers: any[] = ['']
    list.forEach((item) => {
      if (!headers.includes(item.roleCode)) headers.push(item.roleCode)
      let newItem = {}
      if (
        Object.values(sections)
          .flat()
          .find((s) => s.permissionId === item.permissionId)
      ) {
        newItem = Object.values(sections)
          .flat()
          .find((s) => s.permissionId === item.permissionId)
        newItem[item.roleCode] = { hasPermission: item.hasPermission, rbacId: item.rbacId }
        if (Object.keys(sections).includes(item.permissionGroup)) {
          sections[item.permissionGroup] = [
            ...sections[item.permissionGroup].filter((o) => o.permissionId !== item.permissionId),
            newItem,
          ]
        } else {
          sections[item.permissionGroup] = [newItem]
        }
      } else {
        newItem['permission'] = item.permissionCode
        newItem['permissionId'] = item.permissionId
        newItem[item.roleCode] = { hasPermission: item.hasPermission, rbacId: item.rbacId }
        if (Object.keys(sections).includes(item.permissionGroup)) {
          sections[item.permissionGroup] = [...sections[item.permissionGroup], newItem]
        } else {
          sections[item.permissionGroup] = [newItem]
        }
      }
    })
    setHeaderData(headers)
    const data: any = []
    Object.keys(sections).forEach((key) => {
      data.push({
        section: key,
        rows: sections[key],
      })
    })
    setData(data)
  }, [list])

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
  }

  if (isLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7' />
      </div>
    )

  return (
    <div className='h-full flex flex-col overflow-hidden'>
      <div className='py-4 text-sm grow overflow-auto'>
        <table className={`w-full max-w-3xl ${isToggling ? 'opacity-50' : 'opacity-100'}`}>
          <thead>
            <tr className='border-b border-gray-300'>
              {headerData.map((header, index) => (
                <th
                  key={`header-${index}`}
                  className={classNames(
                    'text-slate-500 text-left font-normal px-3 py-2 select-none',
                    header !== '' ? 'max-w-[6.25rem]' : '',
                  )}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          {data.map((entry, index) => (
            <tbody key={`entry-${index}`} className='border-b border-gray-300'>
              <tr>
                <td className='text-left font-normal px-3 py-2 text-slate-500'>{entry.section}</td>
              </tr>
              {entry.rows.map((row, index) => (
                <tr key={`${entry.section}-row-${index}`}>
                  {headerData.map((header, index) => (
                    <td
                      key={`${entry.section}-row-${index}-cell-${index}`}
                      className={classNames('px-3 py-2', header === '' ? '' : 'max-w-[6.25rem]')}
                    >
                      {header !== '' ? (
                        row[header] !== undefined ? (
                          <Input
                            mainClasses='!m-0'
                            id={'check'}
                            type={'checkbox'}
                            value={row[header].hasPermission}
                            checked={row[header].hasPermission}
                            onChange={() => handleTogglePermission(row[header])}
                          />
                        ) : null
                      ) : (
                        row['permission']
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ))}
        </table>
      </div>
    </div>
  )
}

export default PermissionsTab
